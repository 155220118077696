<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { getToken, getPermission } from '@/services/user'
export default {
  data () {
    return {
      screenWidth: document.body.clientWidth,
      app_name: process.env.VUE_APP_NAME
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (!this.$store.state.app.initIndex) {
          if (this.$route.name !== 'login' && this.$route.name !== 'resetPassword') {
            getToken().then(data => {
              // console.log(data.data)
              if (!data.data.admin_user) {
                localStorage.removeItem('user')
                this.$router.push({
                  name: 'login'
                })
              } else {
                this.$cookies.set('language', data.data.admin_user.locale)
                this.$store.commit('setRole', data.data.admin_user.role)
              }
            }).catch((e) => {
              this.$router.push({
                name: 'login'
              })
            })
            if (this.app_name !== 'combine') {
              getPermission().then((permissions) => {
                this.$store.commit('setPermission', permissions.data)
              })
            }
          }
          this.$store.commit('app/INIT_ADD', true)
        }
      }
    }
  },
  created () {
    // console.log('screenWidth', this.screenWidth)
    if (this.screenWidth < 426) {
      this.$store.commit('app/TOGGLE_PHONE', true)
    }
  },
  mounted () {
    this.$store.commit('app/INIT_ADD', false)
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
