import Vue from 'vue'
import JSEncrypt from 'jsencrypt';
(function (win) {
  const IS_PRODUCTION = process.env.NODE_ENV == 'production'
  if (!IS_PRODUCTION) {

    // 创建RSA加密对象
    const encryptor = new JSEncrypt()
  
    // 设置公钥
    const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoN3/5I/J46dtmb2s+cTwdiXoN8x5kJHyoWhlzHsIMULFYGVtQn1CSZn2PKuwwQ5vwrvgz+OTBSmb2XNXziXDmBAC+k/4ZMgmFDaLaCsbkegiBvC8X47QK/Ygw/nO6qJKzcRJ3i4NJP+JIzvddogWemZzkMiU9ZJHQMUjOO+9aSdiPTaY0zWRxHdISquONOS6d/MQzwHR0/kCyp8qY0lU7aVGJuEt77g03+RBpya43rCbYOpJeDyaQDMtmxjcPh3hcSjtbWihOX83SmXIodILAOtAAWS6LuTCO/3HTZGFPDDy4GY8vGn/chexSv4cFFOo1i9CvGkVbnNabt9nuZ4jqQIDAQAB'
    encryptor.setPublicKey(publicKey)
  
    // 定义加密方法
    Vue.prototype.$encryptWithRSA = function (data) {
      const encrypted = encryptor.encrypt(data)
      return encrypted
    }
  }
})(window);