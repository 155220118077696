
import i18n from '@/i18n/index'

const productManager = [
  {
    path: '/goodsManager',
    component: () => import('@/views/goodsManager/index.vue'),
    meta: { name: i18n.t('menu.commodityManagement'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 商品列表
        path: '/goodsManager/product',
        component: () => import('@/views/goodsManager/product/index.vue'),
        meta: { name: i18n.t('menu.productList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'goodsManagerProductList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'goodsManagerProductList',
            component: () => import('@/views/goodsManager/product/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'goodsManagerProductAdd',
            component: () => import('@/views/goodsManager/product/update/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'goodsManagerProductEdit',
            component: () => import('@/views/goodsManager/product/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'goodsManagerProductDetail',
            component: () => import('@/views/goodsManager/product/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'goodsManagerProductDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 商品型号
        path: '/goodsManager/variations',
        component: () => import('@/views/goodsManager/variations/index.vue'),
        meta: { name: i18n.t('menu.shopItemStock'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'goodsManagerVariationsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'goodsManagerVariationsList',
            component: () => import('@/views/goodsManager/variations/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'goodsManagerVariationsAdd',
            component: () => import('@/views/goodsManager/variations/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'goodsManagerVariationsEdit',
            component: () => import('@/views/goodsManager/variations/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'goodsManagerVariationsDetail',
            component: () => import('@/views/goodsManager/variations/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'goodsManagerVariationsDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 商品类别
        path: '/goodsManager/categories',
        component: () => import('@/views/goodsManager/categories/index.vue'),
        meta: { name: i18n.t('menu.shopCategories'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'goodsManagerCategoriesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'goodsManagerCategoriesList',
            component: () => import('@/views/goodsManager/categories/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'goodsManagerCategoriesAdd',
            component: () => import('@/views/goodsManager/categories/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'goodsManagerCategoriesEdit',
            component: () => import('@/views/goodsManager/categories/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'goodsManagerCategoriesDetail',
            component: () => import('@/views/goodsManager/categories/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 商品子类别
        path: '/goodsManager/subCategories',
        component: () => import('@/views/goodsManager/subCategories/index.vue'),
        meta: { name: i18n.t('menu.shopSubCategories'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'goodsManagerSubCategoriesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'goodsManagerSubCategoriesList',
            component: () => import('@/views/goodsManager/subCategories/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'goodsManagerSubCategoriesAdd',
            component: () => import('@/views/goodsManager/subCategories/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'goodsManagerSubCategoriesEdit',
            component: () => import('@/views/goodsManager/subCategories/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'goodsManagerSubCategoriesDetail',
            component: () => import('@/views/goodsManager/subCategories/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 商品标签
        path: '/goodsManager/commonLabels',
        component: () => import('@/views/goodsManager/commonLabels/index.vue'),
        meta: { name: i18n.t('menu.shopItemLabels'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'goodsManagerCommonLabelsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'goodsManagerCommonLabelsList',
            component: () => import('@/views/goodsManager/commonLabels/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'goodsManagerCommonLabelsAdd',
            component: () => import('@/views/goodsManager/commonLabels/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'goodsManagerCommonLabelsEdit',
            component: () => import('@/views/goodsManager/commonLabels/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'goodsManagerCommonLabelsDetail',
            component: () => import('@/views/goodsManager/commonLabels/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default productManager
