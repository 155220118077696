import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import store from '@/store'
import i18n from '@/i18n/index'
// 路由分模块导入
import productManager from './modules/productManager'
import orderManager from './modules/orderManager'
// import supplierManager from './modules/supplierManager'
// import userManager from './modules/userManager'
// import stroeDecoration from './modules/stroeDecoration'
import promotionManager from './modules/promotionManager'
import buddyManager from './modules/buddyManager'
import rewardsStore from './modules/rewardsStore'
import storesManager from './modules/storesManager'
import decorationManager from './modules/decorationManager'
import couponManager from './modules/couponManager'
// import attractionsManager from './modules/attractionsManager'
import bundleManager from './modules/bundleManager'
import suppliersManager from './modules/suppliersManager'
import memberManager from './modules/memberManager'
import notificationManager from './modules/notificationManager'
import prizesManager from './modules/prizesManager'
import settingManager from './modules/settingManager'
import zooTicketsManager from './modules/zooTicketsManager'
import roomManager from './modules/roomManager'
// import hotelManager from './modules/hotelManager'
import basicMsgManager from './modules/basicMsgManager'
import restsManager from './modules/restsManager'
import contentManager from './modules/contentManager'
Vue.use(VueRouter)

const app_name = process.env.VUE_APP_NAME
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 路由配置规则
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/login/index.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/resetPassword/index.vue')
  },
  {
    path: '/',
    component: Layout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '', // 默认子路由
        name: 'home',
        component: () => import(/* webpackChunkName: 'home' */ '@/views/home/index.vue'),
        meta: { name: i18n.t('menu.dataKanban'), show: true, index: '/', menu: true }
        // redirect: '/ordersManager/managerDeliveryOrders'
        // redirect: {
        //   name: 'ordersManagerDeliveryOrdersList'
        // }
      },
      ...productManager,
      ...orderManager,
      // ...supplierManager,
      // ...userManager,
      // ...stroeDecoration,
      ...promotionManager,
      ...buddyManager,
      ...rewardsStore,
      ...storesManager,
      ...decorationManager,
      ...restsManager,
      ...contentManager,
      ...couponManager,
      // ...attractionsManager,
      ...bundleManager,
      ...suppliersManager,
      ...memberManager,
      ...notificationManager,
      ...prizesManager,
      ...settingManager,
      ...zooTicketsManager,
      // ...hotelManager,
      ...roomManager,
      ...basicMsgManager,
      {
        path: '/downloadCenter/downloadCenter',
        name: 'downloadCenter',
        component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
        meta: { name: i18n.t('menu.downloadCenter'), show: true, index: '/downloadCenter/downloadCenter', menu: true }
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import( '@/views/error-page/404.vue')
  }
]

const router = new VueRouter({
  routes
})

// 全局前置守卫：任何页面的访问都要经过这里
// to：要去哪里的路由信息
// from：从哪里来的路由信息
// next：通行的标志
router.beforeEach((to, from, next) => {
  // to.matched 是一个数组（匹配到是路由记录）
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user) {
      // 跳转到登录页面
      next({
        name: 'login',
        query: { // 通过 url 传递查询字符串参数
          redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
        }
      })
    } else {
      next() // 允许通过
    }
  } else {
    next() // 允许通过
  }

//   // // 路由守卫中一定要调用 next，否则页码无法展示
//   // next()
//   // if (to.path !== '/login') {
//   //   // 校验登录状态
//   // }
})
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
/**
 * @desc 全局监听路由变化
 */
router.beforeEach((to, from, next) => {
  if (app_name === 'zoo' && to.name === 'home') {
    next({
      name: 'ordersManagerticketOrdersList'
    })
  }
  next()
})
export default router
