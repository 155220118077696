
import i18n from '@/i18n/index'

const rewardsStore = [
  {
    path: '/rewardsStore',
    component: () => import('@/views/rewardsStore/index.vue'),
    meta: { name: i18n.t('menu.rewardsStore'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 积分商品
        path: '/rewardsStore/rewardsProduct',
        component: () => import('@/views/rewardsStore/rewardsProduct/index.vue'),
        meta: { name: i18n.t('menu.rewardsProducts'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'rewardsStoreRewardsProductList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'rewardsStoreRewardsProductList',
            component: () => import('@/views/rewardsStore/rewardsProduct/list/index.vue'),
            meta: { name: i18n.t('text.list') }
          },
          {
            // 新增
            path: 'add',
            name: 'rewardsStoreRewardsProductAdd',
            component: () => import('@/views/rewardsStore/rewardsProduct/add/index.vue'),
            meta: { name: i18n.t('text.add') }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'rewardsStoreRewardsProductEdit',
            component: () => import('@/views/rewardsStore/rewardsProduct/edit/index.vue'),
            meta: { name: i18n.t('text.edit') }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'rewardsStoreRewardsProductDetail',
            component: () => import('@/views/rewardsStore/rewardsProduct/detail/index.vue'),
            meta: { name: i18n.t('text.detail') }
          }
        ]
      },
      {
        // 商品类别
        path: '/rewardsStore/productCategories',
        component: () => import('@/views/rewardsStore/productCategories/index.vue'),
        meta: { name: i18n.t('menu.productCategories'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'rewardsStoreProductCategoriesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'rewardsStoreProductCategoriesList',
            component: () => import('@/views/rewardsStore/productCategories/list/index.vue'),
            meta: { name: i18n.t('text.list') }
          },
          {
            // 新增
            path: 'add',
            name: 'rewardsStoreProductCategoriesAdd',
            component: () => import('@/views/rewardsStore/productCategories/add/index.vue'),
            meta: { name: i18n.t('text.add') }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'rewardsStoreProductCategoriesEdit',
            component: () => import('@/views/rewardsStore/productCategories/edit/index.vue'),
            meta: { name: i18n.t('text.edit') }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'rewardsStoreProductCategoriesDetail',
            component: () => import('@/views/rewardsStore/productCategories/detail/index.vue'),
            meta: { name: i18n.t('text.detail') }
          }
        ]
      }
    ]
  }
]

export default rewardsStore
