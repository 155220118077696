
import i18n from '@/i18n/index'

const prizesManager = [
  {
    path: '/prizesManager',
    component: () => import('@/views/prizesManager/index.vue'),
    meta: { name: i18n.t('menu.luckyDraw'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 奖品管理
        path: '/prizesManager/prizes',
        component: () => import('@/views/prizesManager/prizes/index.vue'),
        meta: { name: i18n.t('menu.prizeManagement'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'prizesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'prizesList',
            component: () => import('@/views/prizesManager/prizes/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'prizesAdd',
            component: () => import('@/views/prizesManager/prizes/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'prizesEdit',
            component: () => import('@/views/prizesManager/prizes/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'prizesDetail',
            component: () => import('@/views/prizesManager/prizes/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 抽奖记录
        path: '/prizesManager/participation',
        component: () => import('@/views/prizesManager/participation/index.vue'),
        meta: { name: i18n.t('menu.participationRecords'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'participationList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'participationList',
            component: () => import('@/views/prizesManager/participation/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'participationDetail',
            component: () => import('@/views/prizesManager/participation/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 中奖记录
        path: '/prizesManager/winning',
        component: () => import('@/views/prizesManager/winning/index.vue'),
        meta: { name: i18n.t('menu.winningRecords'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'winningList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'winningList',
            component: () => import('@/views/prizesManager/winning/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'winningDetail',
            component: () => import('@/views/prizesManager/winning/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 抽奖机会
        path: '/prizesManager/luckyDraw',
        component: () => import('@/views/prizesManager/luckyDraw/index.vue'),
        meta: { name: i18n.t('menu.luckyDrawChance'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'luckyDrawList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'luckyDrawList',
            component: () => import('@/views/prizesManager/luckyDraw/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'luckyDrawAdd',
            component: () => import('@/views/prizesManager/luckyDraw/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'luckyDrawEdit',
            component: () => import('@/views/prizesManager/luckyDraw/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'luckyDrawDetail',
            component: () => import('@/views/prizesManager/luckyDraw/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default prizesManager
