
import i18n from '@/i18n/index'

const contentManager = [
  {
    path: '/contentManager',
    component: () => import('@/views/contentManager/index.vue'),
    meta: { name: i18n.t('menu.contentManagement'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 内容列表
        path: '/contentManager/content',
        component: () => import('@/views/contentManager/content/index.vue'),
        meta: { name: i18n.t('menu.contentManagement'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'contentList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'contentList',
            component: () => import('@/views/contentManager/content/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'contentDetail',
            component: () => import('@/views/contentManager/content/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 编辑
            path: 'update',
            name: 'contentUpdate',
            component: () => import('@/views/contentManager/content/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          }
        ]
      }

    ]
  }
]

export default contentManager
