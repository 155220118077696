/**
 * 用户相关请求模块
 */

import request from '@/utils/request'
// import qs from 'qs'

export const login = (data) => {
  return request({
    method: 'POST',
    url: '/v1/admin_users/signin',
    data
    // 如果 data 是普通对象，则 Content-Type 是 application/json
    // 如果 data 是 qs.stringify(data) 转换之后的数据：key=value&key=value，则 Content-Type 会被设置为 application/x-www-form-urlencoded
    // 如果 data 是 FormData 对象，则 Content-Type 是 multipart/form-data
    // data: qs.stringify(data) // axios 默认发送的是 application/json 格式的数据
  })
}

// 校验token
export const getToken = () => {
  return request({
    method: 'GET',
    url: '/v1/admin_users/me'
  })
}

// 退出登录
export const signout = () => {
  return request({
    method: 'POST',
    url: '/v1/admin_users/signout'
  })
}

// 修改语言配置
export const putManagerLang = (id, data) => {
  return request({
    method: 'PUT',
    url: `/v1/admin_users/${id}`,
    data
  })
}

export const sendResetPasswordEmail = (data) => {
  return request({
    url: '/v1/admin_users/send_reset_password_email',
    method: 'POST',
    data
  })
}

export const resetPassword = (data) => {
  return request({
    url: '/v1/admin_users/email_reset_password',
    method: 'POST',
    data
  })
}

export const getPermission = () => {
  return request({
    url: '/cms/metadata/permissions',
    method: 'GET',
  })
}

export const getPermissionList = () => {
  return request({
    url: '/cms/metadata/preset_permissions',
    method: 'GET',
  })
}
