
import i18n from '@/i18n/index'

const restsManager = [
  {
    path: '/restsManager',
    component: () => import('@/views/restsManager/index.vue'),
    meta: { name: i18n.t('menu.hotels'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 酒店列表
        path: '/restsManager/rest',
        component: () => import('@/views/restsManager/rest/index.vue'),
        meta: { name: i18n.t('menu.hotelList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restsManagerRestList'
        },
        children: [
          {
            path: 'list',
            name: 'restsManagerRestList',
            component: () => import('@/views/restsManager/rest/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            path: 'detail/:id',
            name: 'restsManagerRestDetail',
            component: () => import('@/views/restsManager/rest/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: true }
          },
          {
            // 编辑
            path: 'update',
            name: 'restsManagerRestUpdate',
            component: () => import('@/views/restsManager/rest/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
        ]
      },
      {
        // 酒店设施
        path: '/restsManager/facility',
        component: () => import('@/views/restsManager/facility/index.vue'),
        meta: { name: i18n.t('menu.hotelFacility'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restsManagerFacilityList'
        },
        children: [
          {
            path: 'list',
            name: 'restsManagerFacilityList',
            component: () => import('@/views/restsManager/facility/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            path: 'detail/:id',
            name: 'restsManagerFacilityDetail',
            component: () => import('@/views/restsManager/facility/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: true }
          },
          {
            // 编辑
            path: 'update',
            name: 'restsManagerFacilityUpdate',
            component: () => import('@/views/restsManager/facility/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
        ]
      },
      {
        // 酒店标签
        path: '/restsManager/label',
        component: () => import('@/views/restsManager/label/index.vue'),
        meta: { name: i18n.t('menu.hotelLabel'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restsManagerLabelList'
        },
        children: [
          {
            path: 'list',
            name: 'restsManagerLabelList',
            component: () => import('@/views/restsManager/label/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            path: 'detail/:id',
            name: 'restsManagerLabelDetail',
            component: () => import('@/views/restsManager/label/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: true }
          },
          {
            // 编辑
            path: 'update',
            name: 'restsManagerLabelUpdate',
            component: () => import('@/views/restsManager/label/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
        ]
      },
      {
        // 酒店房型
        path: '/restsManager/type',
        component: () => import('@/views/restsManager/category/index.vue'),
        meta: { name: i18n.t('menu.roomList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restsManagerCategoryList'
        },
        children: [
          {
            path: 'list',
            name: 'restsManagerCategoryList',
            component: () => import('@/views/restsManager/category/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            path: 'detail/:id',
            name: 'restsManagerCategoryDetail',
            component: () => import('@/views/restsManager/category/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: true }
          },
          {
            // 编辑
            path: 'update',
            name: 'restsManagerCategoryUpdate',
            component: () => import('@/views/restsManager/category/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          }
        ]
      },
      {
        // 酒店附加服务
        path: '/restsManager/addon',
        component: () => import('@/views/restsManager/addon/index.vue'),
        meta: { name: i18n.t('menu.hotelAddons'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restsManagerAddonList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'restsManagerAddonList',
            component: () => import('@/views/restsManager/addon/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'update',
            name: 'restsManagerAddonUpdate',
            component: () => import('@/views/restsManager/addon/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'restsManagerAddonDetail',
            component: () => import('@/views/restsManager/addon/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 酒店房间管理
        path: '/restsManager/room',
        component: () => import('@/views/restsManager/room/index.vue'),
        meta: { name: i18n.t('menu.roomsSetting'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restsManagerRoomList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'restsManagerRoomList',
            component: () => import('@/views/restsManager/room/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'update',
            name: 'restsManagerRoomUpdate',
            component: () => import('@/views/restsManager/room/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'restsManagerRoomDetail',
            component: () => import('@/views/restsManager/room/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 房价房态管理
        path: '/restsManager/housingstatus',
        component: () => import('@/views/restsManager/housingstatus/index.vue'),
        meta: { name: i18n.t('menu.hotelStatus'), allowBreadcrumbRedirect: true },
        redirect: {
        name: 'restsManagerHousingStatusList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'restsManagerHousingStatusList',
            component: () => import('@/views/restsManager/housingstatus/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          }
        ]
      }
    ]
  }
]

export default restsManager
