import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import commonApiUrl from '@/common/config/index'
import cookie from 'vue-cookies'
// import router from '@/router'
// import qs from 'qs'
const clientId = _getClientId()
// const env = process.env.NODE_ENV

function _getClientId () {
  // let id = uni.getStorageSync('clientId')
  let id = localStorage.getItem('clientId')
  if (!id) {
    const randomStr = Math.round(Math.random() * 1000000)
    const timestamp = new Date().getTime()
    id = `Wechat_Mini_${timestamp}${randomStr}`
    // uni.setStorageSync('clientId', id);
    localStorage.setItem('clientId', id)
  }
  return id
}

const request = axios.create({
  // 配置选项
  // baseURL,
  // timeout
  baseURL: commonApiUrl,
  timeout: 600000,
  headers: {
    'X-APP-KEY': 'Z1atkgygmbOTojjwQCQMYiWHhH4',
    'X-CLIENT-ID': clientId,
    'X-ACCESS-TOKEN': '',
    'X-USER-ID': ''
  }
})

// function redirectLogin () {
//   router.push({
//     name: 'login',
//     query: {
//       redirect: router.currentRoute.fullPath
//     }
//   })
// }
function errorOperate (data) {
  if (data.response.data && data.response.data instanceof Blob) {
    var reader = new FileReader()
    reader.addEventListener('loadend', function (e) {
      Message.error(JSON.parse(e.target.result).errors[0])
    })
    reader.readAsText(data.response.data)
  } else if (data.response.data && data.response.data.errors) {
    Message.error(data.response.data.errors[0])
  } else if (data.response.data && data.response.data.error) {
    Message.error(data.response.data.error)
  }
}
// function refreshToken () {
//   return axios.create()({
//     method: 'POST',
//     url: '/front/user/refresh_token',
//     data: qs.stringify({
//       // refresh_token 只能使用1次
//       refreshtoken: store.state.user.refresh_token
//     })
//   })
// }

// 请求拦截器
request.interceptors.request.use(function (config) {
  // 我们就在这里通过改写 config 配置信息来实现业务功能的统一处理
  // const { user } = store.state
  // if (user && user.access_token && user.id) {
  //   config.headers['X-ACCESS-TOKEN'] = user.access_token
  //   config.headers['X-USER-ID'] = user.id.toString()
  // }
  const user = JSON.parse(window.localStorage.getItem('user') || 'null')
  // console.log(user)
  if (user && user.access_token && user.id) {
    config.headers['X-ACCESS-TOKEN'] = user.access_token
    config.headers['X-USER-ID'] = user.id.toString()
  }
  let language = cookie.get('language') || navigator.language || navigator.userLanguage || 'zh-CN'
  // language = language === 'zh-CN' ? 'zh' : language
  // language = language === 'en-US' ? 'en' : language
  language = language.split('-')[0]
  config.headers.LOCALE = language
  config.headers.Accept = 'application/json'
  // 注意：这里一定要返回 config，否则请求就发不出去了
  if (store.url) config.url = store.url
  // config.url = store.url
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// 响应拦截器
// let isRfreshing = false // 控制刷新 token 的状态
// let requests = [] // 存储刷新 token 期间过来的 401 请求
request.interceptors.response.use(function (response) { // 状态码为 2xx 都会进入这里
  // console.log('请求响应成功了 => ', response)
  // 如果是自定义错误状态码，错误处理就写到这里
  // console.log(response)
  return response
}, async function (error) { // 超出 2xx 状态码都都执行这里
  // console.log('请求响应失败了 => ', error)
  // 如果是使用的 HTTP 状态码，错误处理就写到这里
  // console.dir(error)
  // store.commit('app/LOADING_MODIFY')
  // console.log(store)
  if (store.state.app.loading) store.state.app.loading.close()
  console.log(error.response, error.request, error.message)
  if (error.response) { // 请求发出去收到响应了，但是状态码超出了 2xx 范围
    errorOperate(error)
  } else if (error.request) { // 请求发出去没有收到响应
    Message.error('请求超时，请刷新重试')
  } else { // 在设置请求时发生了一些事情，触发了一个错误
    Message.error(`请求失败：${error.message}`)
  }

  // 把请求失败的错误对象继续抛出，扔给上一个调用者
  return Promise.reject(error)
})

export default request
