
import i18n from '@/i18n/index'

const promotionManager = [
  {
    path: '/promotionManager',
    component: () => import('@/views/promotionManager/campaign/index.vue'),
    children: [
      {
        // 活动
        path: '/promotionManager/campaign',
        component: () => import('@/views/promotionManager/campaign/index.vue'),
        meta: { name: i18n.t('menu.campaigns'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'promotionManagerCampaignList'
        },
        children: [
          {
            path: '/promotionManager/campaign/list',
            name: 'promotionManagerCampaignList',
            component: () => import('@/views/promotionManager/campaign/list/index.vue'),
            meta: { name: i18n.t('menu.campaigns'), keepAlive: true }
          },
          {
            path: '/promotionManager/campaign/detail/:id',
            name: 'promotionManagerCampaignDetail',
            component: () => import('@/views/promotionManager/campaign/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: '/promotionManager/campaign/add',
            name: 'promotionManagerCampaignAdd',
            meta: { index: 15, keepAlive: false },
            component: () => import('@/views/promotionManager/campaign/add/index.vue'),
            children: [
              // {
              //   path: '',
              //   redirect: { name: '404' }
              // }, 
              {
                path: 'activity',
                name: 'promotionManagerCampaignAddActivity',
                meta: { name: i18n.t('menu.addActivity') },
                component: () => import('@/views/promotionManager/campaign/add/activity/index.vue')
              }, {
                path: 'activityProduct/:activityId',
                name: 'promotionManagerCampaignAddActivityProduct',
                meta: { name: i18n.t('menu.addActivityProduct') },
                component: () => import('@/views/promotionManager/campaign/add/activityProduct/index.vue')
              }, {
                path: '*',
                redirect: { name: '404' }
              }
            ]
          },
          {
            path: '/promotionManager/campaign/edit/activity/:id',
            name: 'promotionManagerCampaignEditActivity',
            meta: { name: i18n.t('menu.editActivity'), keepAlive: false },
            component: () => import('@/views/promotionManager/campaign/edit/activity/index.vue')
          },
          {
            path: '/promotionManager/campaign/edit/activityProduct/:activityId',
            name: 'promotionManagerCampaignEditActivityProduct',
            meta: { name: i18n.t('menu.editActivityProduct'), keepAlive: false },
            component: () => import('@/views/promotionManager/campaign/edit/activityProduct/index.vue')
          }
        ]
      },
      {
        path: '/promotionManager/flexiCombo',
        component: () => import('@/views/promotionManager/flexiCombo/index.vue'),
        meta: { name: i18n.t('menu.promotions'), allowBreadcrumbRedirect: false },
        children: [
          {
            // flexi combo
            path: '/promotionManager/flexiCombo',
            component: () => import('@/views/promotionManager/flexiCombo/index.vue'),
            meta: { name: i18n.t('menu.flexiCombo'), allowBreadcrumbRedirect: true },
            redirect: {
              name: 'flexiComboList'
            },
            children: [
              {
                // 列表
                path: 'list',
                name: 'flexiComboList',
                component: () => import('@/views/promotionManager/flexiCombo/list/index.vue'),
                meta: { name: i18n.t('text.list') }
              },
              {
                // 新增
                path: 'add',
                name: 'flexiComboAdd',
                component: () => import('@/views/promotionManager/flexiCombo/add/index.vue'),
                meta: { name: i18n.t('text.add') }
              },
              {
                // 编辑
                path: 'edit/:id',
                name: 'flexiComboEdit',
                component: () => import('@/views/promotionManager/flexiCombo/edit/index.vue'),
                meta: { name: i18n.t('text.edit') }
              },
              {
                // 商品列表
                path: 'productList',
                name: 'flexiComboroductList',
                component: () => import('@/views/promotionManager/flexiCombo/activityProduct/index.vue'),
                meta: { name: i18n.t('text.add') }
              }
            ]
          }
        ]
      },
      {
        path: '/promotionManager/referral',
        component: () => import('@/views/promotionManager/referral/index.vue'),
        meta: { name: i18n.t('menu.promotions'), allowBreadcrumbRedirect: false },
        children: [
          {
            // 邀请好友
            path: '/promotionManager/referral',
            component: () => import('@/views/promotionManager/referral/index.vue'),
            meta: { name: i18n.t('menu.referralManager'), allowBreadcrumbRedirect: true },
            redirect: {
              name: 'referralAdd'
            },
            children: [
              {
                // 新增
                path: 'add',
                name: 'referralAdd',
                component: () => import('@/views/promotionManager/referral/add/index.vue'),
                meta: { name: i18n.t('text.add') }
              }
            ]
          }
        ]
      }
    ]
  }
]

export default promotionManager
