
import i18n from '@/i18n/index'

const settingManager = [
  {
    path: '/settingManager',
    component: () => import('@/views/settingManager/index.vue'),
    meta: { name: i18n.t('menu.setting'), allowBreadcrumbRedirect: false },
    children: [
      {
        // admin设置
        path: '/settingManager/admin',
        component: () => import('@/views/settingManager/admin/index.vue'),
        meta: { name: i18n.t('menu.adminUser'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'adminsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'adminsList',
            component: () => import('@/views/settingManager/admin/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'adminsAdd',
            component: () => import('@/views/settingManager/admin/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'adminsEdit',
            component: () => import('@/views/settingManager/admin/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'adminsDetail',
            component: () => import('@/views/settingManager/admin/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // clients设置
        path: '/settingManager/client',
        component: () => import('@/views/settingManager/client/index.vue'),
        meta: { name: i18n.t('menu.clients'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'clientsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'clientsList',
            component: () => import('@/views/settingManager/client/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'clientsEdit',
            component: () => import('@/views/settingManager/client/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          }
        ]
      }, {
        // countries设置
        path: '/settingManager/countries',
        component: () => import('@/views/settingManager/countries/index.vue'),
        meta: { name: i18n.t('menu.countries'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'countriesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'countriesList',
            component: () => import('@/views/settingManager/countries/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'countriesAdd',
            component: () => import('@/views/settingManager/countries/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'countriesEdit',
            component: () => import('@/views/settingManager/countries/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'countriesDetail',
            component: () => import('@/views/settingManager/countries/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // city设置
        path: '/settingManager/cities',
        component: () => import('@/views/settingManager/cities/index.vue'),
        meta: { name: i18n.t('menu.countries'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'citiesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'citiesList',
            component: () => import('@/views/settingManager/cities/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'citiesDetail',
            component: () => import('@/views/settingManager/cities/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 新增
            path: 'update',
            name: 'citiesUpdate',
            component: () => import('@/views/settingManager/cities/update/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default settingManager
