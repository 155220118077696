
import i18n from '@/i18n/index'

const decorationManager = [
  {
    path: '/decorationManager',
    component: () => import('@/views/decorationManager/index.vue'),
    meta: { name: i18n.t('menu.decoration'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 广告栏
        path: '/decorationManager/banner',
        component: () => import('@/views/decorationManager/banner/index.vue'),
        meta: { name: i18n.t('menu.banner'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'bannerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'bannerList',
            component: () => import('@/views/decorationManager/banner/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'bannerAdd',
            component: () => import('@/views/decorationManager/banner/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'bannerEdit',
            component: () => import('@/views/decorationManager/banner/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'bannerDetail',
            component: () => import('@/views/decorationManager/banner/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 弹窗
        path: '/decorationManager/popups',
        component: () => import('@/views/decorationManager/popups/index.vue'),
        meta: { name: i18n.t('menu.popUps'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'popupsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'popupsList',
            component: () => import('@/views/decorationManager/popups/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'popupsAdd',
            component: () => import('@/views/decorationManager/popups/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'popupsEdit',
            component: () => import('@/views/decorationManager/popups/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'popupsDetail',
            component: () => import('@/views/decorationManager/popups/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 导航
        path: '/decorationManager/navigation',
        component: () => import('@/views/decorationManager/navigation/index.vue'),
        meta: { name: i18n.t('menu.navigation'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'navigationList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'navigationList',
            component: () => import('@/views/decorationManager/navigation/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'navigationAdd',
            component: () => import('@/views/decorationManager/navigation/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'navigationEdit',
            component: () => import('@/views/decorationManager/navigation/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'navigationDetail',
            component: () => import('@/views/decorationManager/navigation/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 定制页面
        path: '/decorationManager/page',
        component: () => import('@/views/decorationManager/page/index.vue'),
        meta: { name: i18n.t('menu.customizedPage'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'pageList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'pageList',
            component: () => import('@/views/decorationManager/page/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'pageAdd',
            component: () => import('@/views/decorationManager/page/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'pageEdit',
            component: () => import('@/views/decorationManager/page/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'pageDetail',
            component: () => import('@/views/decorationManager/page/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 热门搜索
        path: '/decorationManager/hotSearch',
        component: () => import('@/views/decorationManager/hotSearch/index.vue'),
        meta: { name: i18n.t('menu.hotSearch'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'hotSearchList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'hotSearchList',
            component: () => import('@/views/decorationManager/hotSearch/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'hotSearchAdd',
            component: () => import('@/views/decorationManager/hotSearch/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'hotSearchEdit',
            component: () => import('@/views/decorationManager/hotSearch/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'hotSearchDetail',
            component: () => import('@/views/decorationManager/hotSearch/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default decorationManager
