import i18n from '@/i18n/index'

const bundleManager = [
  {
    path: '/bundleManager',
    component: () => import('@/views/bundleManager/index.vue'),
    meta: { name: i18n.t('menu.campaigns'), allowBreadcrumbRedirect: false },
    children: [
      {
        path: '/bundleManager/bundles',
        component: () => import('@/views/bundleManager/bundles/index.vue'),
        meta: { name: i18n.t('menu.bundleManager'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'bundleManagerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'bundleManagerList',
            component: () => import('@/views/bundleManager/bundles/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 添加
            path: 'add',
            name: 'addBundle',
            component: () => import('@/views/bundleManager/bundles/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'bundleEdit',
            component: () => import('@/views/bundleManager/bundles/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'bundleDetail',
            component: () => import('@/views/bundleManager/bundles/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 套餐商品
        path: '/bundleManager/bundleProducts',
        component: () => import('@/views/bundleManager/bundleProduct/index.vue'),
        meta: { name: i18n.t('menu.bundleProduct'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'bundleProductList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'bundleProductList',
            component: () => import('@/views/bundleManager/bundleProduct/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 添加
            path: 'add',
            name: 'addBundleProduct',
            component: () => import('@/views/bundleManager/bundleProduct/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'bundleProductEdit',
            component: () => import('@/views/bundleManager/bundleProduct/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'bundleProductDetail',
            component: () => import('@/views/bundleManager/bundleProduct/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default bundleManager
