
import i18n from '@/i18n/index'

const storeManager = [
  {
    path: '/storesManager',
    component: () => import('@/views/storesManager/index.vue'),
    meta: { name: i18n.t('menu.storesManager'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 店铺
        path: '/storesManager/shopsManager',
        component: () => import('@/views/storesManager/shopsManager/index.vue'),
        meta: { name: i18n.t('menu.shopsManager'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'shopsManagerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'shopsManagerList',
            component: () => import('@/views/storesManager/shopsManager/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true  }
          },
          {
            // 新增
            path: 'add',
            name: 'shopsManagerAdd',
            component: () => import('@/views/storesManager/shopsManager/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false  }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'shopsManagerEdit',
            component: () => import('@/views/storesManager/shopsManager/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false  }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'shopsManagerDetail',
            component: () => import('@/views/storesManager/shopsManager/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false  }
          }
        ]
      }, {
        // 门店
        path: '/storesManager/storesManager',
        component: () => import('@/views/storesManager/storesManager/index.vue'),
        meta: { name: i18n.t('form.store.offlineShop'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'storesManagerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'storesManagerList',
            component: () => import('@/views/storesManager/storesManager/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'storesManagerAdd',
            component: () => import('@/views/storesManager/storesManager/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'storesManagerEdit',
            component: () => import('@/views/storesManager/storesManager/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'storesManagerDetail',
            component: () => import('@/views/storesManager/storesManager/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 短信记录
        path: '/storesManager/smsRecordManager',
        component: () => import('@/views/storesManager/smsRecordManager/index.vue'),
        meta: { name: i18n.t('form.store.SMSRecord'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'smsRecordList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'smsRecordList',
            component: () => import('@/views/storesManager/smsRecordManager/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          }
        ]
      }, {
        // 店铺标签
        path: '/storesManager/shopsLabels',
        component: () => import('@/views/storesManager/shopsLabels/index.vue'),
        meta: { name: i18n.t('form.store.categories'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'shopsLabelsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'shopsLabelsList',
            component: () => import('@/views/storesManager/shopsLabels/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'shopsLabelsAdd',
            component: () => import('@/views/storesManager/shopsLabels/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'shopsLabelsEdit',
            component: () => import('@/views/storesManager/shopsLabels/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'shopsLabelsDetail',
            component: () => import('@/views/storesManager/shopsLabels/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 配送区域
        path: '/storesManager/deliveryZones',
        component: () => import('@/views/storesManager/deliveryZones/index.vue'),
        meta: { name: i18n.t('form.store.deliveryZones'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'deliveryZonesList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'deliveryZonesList',
            component: () => import('@/views/storesManager/deliveryZones/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'deliveryZonesAdd',
            component: () => import('@/views/storesManager/deliveryZones/edit/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'deliveryZonesEdit',
            component: () => import('@/views/storesManager/deliveryZones/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'deliveryZonesDetail',
            component: () => import('@/views/storesManager/deliveryZones/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default storeManager
