
import i18n from '@/i18n/index'

const basicMsgManager = [
  {
    path: '/basicMsgManager',
    component: () => import('@/views/basicMsgManager/index.vue'),
    meta: { name: i18n.t('menu.informationManagement'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 景点管理
        path: '/basicMsgManager/attractions',
        component: () => import('@/views/attractionsManager/attractions/index.vue'),
        meta: { name: i18n.t('menu.attractionsList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'attractionsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'attractionsList',
            component: () => import('@/views/attractionsManager/attractions/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'attractionsAdd',
            component: () => import('@/views/attractionsManager/attractions/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'attractionsEdit',
            component: () => import('@/views/attractionsManager/attractions/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'attractionsDetail',
            component: () => import('@/views/attractionsManager/attractions/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 演出列表
        path: '/basicMsgManager/hotels',
        component: () => import('@/views/basicMsgManager/hotels/index.vue'),
        meta: { name: i18n.t('menu.showList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'hotelList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'hotelList',
            component: () => import('@/views/basicMsgManager/hotels/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'hotelAdd',
            component: () => import('@/views/basicMsgManager/hotels/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'hotelEdit',
            component: () => import('@/views/basicMsgManager/hotels/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'hotelDetail',
            component: () => import('@/views/basicMsgManager/hotels/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 标签管理
        path: '/attractionsManager/siteLabels',
        component: () => import('@/views/attractionsManager/siteLabels/index.vue'),
        meta: { name: i18n.t('menu.siteLabels'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'siteLabelsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'siteLabelsList',
            component: () => import('@/views/attractionsManager/siteLabels/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'siteLabelsAdd',
            component: () => import('@/views/attractionsManager/siteLabels/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'siteLabelsEdit',
            component: () => import('@/views/attractionsManager/siteLabels/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'siteLabelsDetail',
            component: () => import('@/views/attractionsManager/siteLabels/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 餐厅列表
        path: '/basicMsgManager/restaurants',
        component: () => import('@/views/basicMsgManager/restaurants/index.vue'),
        meta: { name: i18n.t('menu.restaurantList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'restaurantsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'restaurantsList',
            component: () => import('@/views/basicMsgManager/restaurants/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'restaurantsAdd',
            component: () => import('@/views/basicMsgManager/restaurants/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'restaurantsEdit',
            component: () => import('@/views/basicMsgManager/restaurants/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'restaurantsDetail',
            component: () => import('@/views/basicMsgManager/restaurants/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 活动列表
        path: '/basicMsgManager/campaigns',
        component: () => import('@/views/basicMsgManager/campaigns/index.vue'),
        meta: { name: i18n.t('menu.campaignList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'campaignsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'campaignList',
            component: () => import('@/views/basicMsgManager/campaigns/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'campaignAdd',
            component: () => import('@/views/basicMsgManager/campaigns/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'campaignEdit',
            component: () => import('@/views/basicMsgManager/campaigns/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'campaignDetail',
            component: () => import('@/views/basicMsgManager/campaigns/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 购物列表
        path: '/basicMsgManager/retails',
        component: () => import('@/views/basicMsgManager/retails/index.vue'),
        meta: { name: i18n.t('menu.retailList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'retailList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'retailList',
            component: () => import('@/views/basicMsgManager/retails/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'retailAdd',
            component: () => import('@/views/basicMsgManager/retails/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'retailEdit',
            component: () => import('@/views/basicMsgManager/retails/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'retailDetail',
            component: () => import('@/views/basicMsgManager/retails/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 体验列表
        path: '/basicMsgManager/experiences',
        component: () => import('@/views/basicMsgManager/experiences/index.vue'),
        meta: { name: i18n.t('menu.experienceList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'experienceList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'experienceList',
            component: () => import('@/views/basicMsgManager/experiences/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'experienceAdd',
            component: () => import('@/views/basicMsgManager/experiences/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'experienceEdit',
            component: () => import('@/views/basicMsgManager/experiences/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'experienceDetail',
            component: () => import('@/views/basicMsgManager/experiences/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 优惠列表
        path: '/basicMsgManager/promotions',
        component: () => import('@/views/basicMsgManager/promotions/index.vue'),
        meta: { name: i18n.t('menu.promotionList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'promotionList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'promotionList',
            component: () => import('@/views/basicMsgManager/promotions/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'promotionAdd',
            component: () => import('@/views/basicMsgManager/promotions/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'promotionEdit',
            component: () => import('@/views/basicMsgManager/promotions/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'promotionDetail',
            component: () => import('@/views/basicMsgManager/promotions/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // 演出列表
        path: '/basicMsgManager/shows',
        component: () => import('@/views/basicMsgManager/shows/index.vue'),
        meta: { name: i18n.t('menu.showList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'showList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'showList',
            component: () => import('@/views/basicMsgManager/shows/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'showAdd',
            component: () => import('@/views/basicMsgManager/shows/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'showEdit',
            component: () => import('@/views/basicMsgManager/shows/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'showDetail',
            component: () => import('@/views/basicMsgManager/shows/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default basicMsgManager
