
import i18n from '@/i18n/index'

const zooTicketsManager = [
  {
    path: '/zooTicketsManager',
    component: () => import('@/views/zooTicketsManager/index.vue'),
    meta: { name: i18n.t('menu.zooTicketsManager'), allowBreadcrumbRedirect: false },
    children: [
      {
        path: '/zooTicketsManager/tickets',
        component: () => import('@/views/zooTicketsManager/tickets/index.vue'),
        meta: { name: i18n.t('menu.ticketPackage'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'zooTicketsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'zooTicketsList',
            component: () => import('@/views/zooTicketsManager/tickets/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 列表
            path: 'basicTicketsTypeList/:ticket_package_id_eq?',
            name: 'basicTicketsTypeList',
            component: () => import('@/views/zooTicketsManager/ticketsType/list/index.vue'),
            meta: { name: i18n.t('menu.packageType') + i18n.t('text.list'), keepAlive: true, showDetail: false },
            children: [
              {
                // 新增
                path: 'basic/add/:disabled?/:ticketType?',
                name: 'basicTicketsTypeAdd',
                component: () => import('@/views/zooTicketsManager/ticketsType/add/index.vue'),
                meta: { name: i18n.t('text.add'), keepAlive: false, showDetail: true }
              },
              {
                // 编辑
                path: 'basic/edit/:id/:disabled?/:ticketType?',
                name: 'basicTicketsTypeEdit',
                component: () => import('@/views/zooTicketsManager/ticketsType/edit/index.vue'),
                meta: { name: i18n.t('text.edit'), keepAlive: false, showDetail: true }
              },
              {
                // 详情
                path: 'basic/detail/:id/:ticketType?',
                name: 'basicTicketsTypeDetail',
                component: () => import('@/views/zooTicketsManager/ticketsType/detail/index.vue'),
                meta: { name: i18n.t('text.detail'), keepAlive: false, showDetail: true }
              }
            ]
          }, {
            // 列表
            path: 'basicTicketPackageList/:ticket_package_id_eq?',
            name: 'basicTicketPackageList',
            component: () => import('@/views/zooTicketsManager/ticketPackage/list/index.vue'),
            meta: { name: i18n.t('menu.tPackage') + i18n.t('text.list'), keepAlive: true, showDetail: false },
            children: [
              {
                // 新增
                path: 'basic/add/:disabled?/:ticketType?',
                name: 'basicTicketPackageAdd',
                component: () => import('@/views/zooTicketsManager/ticketPackage/add/index.vue'),
                meta: { name: i18n.t('text.add'), keepAlive: false, showDetail: true }
              },
              {
                // 编辑
                path: 'basic/edit/:id/:disabled?/:ticketType?',
                name: 'basicTicketPackageEdit',
                component: () => import('@/views/zooTicketsManager/ticketPackage/edit/index.vue'),
                meta: { name: i18n.t('text.edit'), keepAlive: false, showDetail: true }
              },
              {
                // 详情
                path: 'basic/detail/:id/:ticketType?',
                name: 'basicTicketPackageDetail',
                component: () => import('@/views/zooTicketsManager/ticketPackage/detail/index.vue'),
                meta: { name: i18n.t('text.detail'), keepAlive: false, showDetail: true }
              }
            ]
          },
          {
            path: 'basicMultiParkTickets/:ticket_package_id_eq?',
            name: 'basicMultiParkTicketsList',
            component: () => import('@/views/zooTicketsManager/multiParkTickets/list/index.vue'),
            meta: { name: i18n.t('menu.multiParkTickets') + i18n.t('text.list'), keepAlive: true, showDetail: false },
            children: [
              {
                // 新增
                path: 'basic/add/:disabled?/:ticketType?',
                name: 'basicMultiParkTicketsAdd',
                component: () => import('@/views/zooTicketsManager/multiParkTickets/add/index.vue'),
                meta: { name: i18n.t('text.add'), keepAlive: false, showDetail: true  }
              },
              {
                // 编辑
                path: 'basic/edit/:id/:disabled?/:ticketType?',
                name: 'basicMultiParkTicketsEdit',
                component: () => import('@/views/zooTicketsManager/multiParkTickets/edit/index.vue'),
                meta: { name: i18n.t('text.edit'), keepAlive: false, showDetail: true  }
              },
              {
                // 详情
                path: 'basic/detail/:id/:ticketType?',
                name: 'basicMultiParkTicketsDetail',
                component: () => import('@/views/zooTicketsManager/multiParkTickets/detail/index.vue'),
                meta: { name: i18n.t('text.detail'), keepAlive: false, showDetail: true  }
              }
            ]
          }, {
            // 新增
            path: 'add/:ticket_package_id_eq?/:ticketType?',
            name: 'zooTicketsAdd',
            component: () => import('@/views/zooTicketsManager/tickets/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'zooTicketsEdit',
            component: () => import('@/views/zooTicketsManager/tickets/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'zooTicketsDetail',
            component: () => import('@/views/zooTicketsManager/tickets/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        path: '/zooTicketsManager/tickets-type',
        component: () => import('@/views/zooTicketsManager/ticketsType/index.vue'),
        meta: { name: i18n.t('menu.packageType'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'zooTicketsTypeList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'zooTicketsTypeList',
            component: () => import('@/views/zooTicketsManager/ticketsType/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'zooTicketsTypeAdd',
            component: () => import('@/views/zooTicketsManager/ticketsType/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'zooTicketsTypeEdit',
            component: () => import('@/views/zooTicketsManager/ticketsType/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'zooTicketsTypeDetail',
            component: () => import('@/views/zooTicketsManager/ticketsType/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        path: '/zooTicketsManager/ticketPackage',
        component: () => import('@/views/zooTicketsManager/ticketPackage/index.vue'),
        meta: { name: i18n.t('menu.tPackage'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ticketPackageList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ticketPackageList',
            component: () => import('@/views/zooTicketsManager/ticketPackage/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'ticketPackageAdd',
            component: () => import('@/views/zooTicketsManager/ticketPackage/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'ticketPackageEdit',
            component: () => import('@/views/zooTicketsManager/ticketPackage/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ticketPackageDetail',
            component: () => import('@/views/zooTicketsManager/ticketPackage/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        path: '/zooTicketsManager/multiParkTickets',
        component: () => import('@/views/zooTicketsManager/multiParkTickets/index.vue'),
        meta: { name: i18n.t('menu.multiParkTickets'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'multiParkTicketsList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'multiParkTicketsList',
            component: () => import('@/views/zooTicketsManager/multiParkTickets/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'multiParkTicketsAdd',
            component: () => import('@/views/zooTicketsManager/multiParkTickets/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'multiParkTicketsEdit',
            component: () => import('@/views/zooTicketsManager/multiParkTickets/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'multiParkTicketsDetail',
            component: () => import('@/views/zooTicketsManager/multiParkTickets/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default zooTicketsManager
