
import i18n from '@/i18n/index'

const memberManager = [
  {
    path: '/memberManager',
    component: () => import('@/views/memberManager/index.vue'),
    meta: { name: i18n.t('menu.members'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 会员列表
        path: '/memberManager/member',
        component: () => import('@/views/memberManager/member/index.vue'),
        meta: { name: i18n.t('menu.memberList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'memberList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'memberList',
            component: () => import('@/views/memberManager/member/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'memberDetail',
            component: () => import('@/views/memberManager/member/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'memberDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          },
          {
            // 添加
            path: 'add',
            name: 'memberAdd',
            component: () => import('@/views/memberManager/member/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'memberEdit',
            component: () => import('@/views/memberManager/member/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 管理地址
            path: 'managerAddress/:id',
            name: 'managerAddress',
            component: () => import('@/views/memberManager/member/managerAddress/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 会员地址编辑
            path: 'memberAddressEdit/:id',
            name: 'memberAddressEdit',
            component: () => import('@/views/memberManager/address/edit/index.vue'),
            meta: { name: i18n.t('text.memberAddressEdit'), keepAlive: false }
          },
          {
            // 会员地址详情
            path: 'memberAddressDetail/:id',
            name: 'memberAddressDetail',
            component: () => import('@/views/memberManager/address/detail/index.vue'),
            meta: { name: i18n.t('text.memberAddressDetail'), keepAlive: false }
          },
          {
            // 会员地址新增
            path: 'memberAddressAdd/:id',
            name: 'memberAddressAdd',
            component: () => import('@/views/memberManager/address/add/index.vue'),
            meta: { name: i18n.t('text.memberAddressAdd'), keepAlive: false }
          }
        ]
      }, {
        // 会员地址
        path: '/memberManager/address',
        component: () => import('@/views/memberManager/address/index.vue'),
        meta: { name: i18n.t('menu.memberAddress'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'addressList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'addressList',
            component: () => import('@/views/memberManager/address/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'addressDetail',
            component: () => import('@/views/memberManager/address/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'memberAddressDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'addressEdit',
            component: () => import('@/views/memberManager/address/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 新增
            path: 'add',
            name: 'addressAdd',
            component: () => import('@/views/memberManager/address/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          }
        ]
      }, {
        // 积分记录
        path: '/memberManager/pointsRecord',
        component: () => import('@/views/memberManager/pointsRecord/index.vue'),
        meta: { name: i18n.t('menu.pointsRecord'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'pointsRecordList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'pointsRecordList',
            component: () => import('@/views/memberManager/pointsRecord/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'pointsRecordDetail',
            component: () => import('@/views/memberManager/pointsRecord/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 手动操作积分
            path: 'operatePoint/:name',
            name: 'pointsRecordOperatePoint',
            component: () => import('@/views/memberManager/pointsRecord/operatePoint/index.vue'),
            meta: { name: i18n.t('form.members.manualTopUp'), keepAlive: false }
          },
          {
            // 批量更新积分
            path: 'updatePoint',
            name: 'pointsRecordUpdatePoint',
            component: () => import('@/views/memberManager/pointsRecord/updatePoint/index.vue'),
            meta: { name: i18n.t('form.members.batchUpdatePoints'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'memberPointDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      }, {
        // 会员等级
        path: '/memberManager/tier',
        component: () => import('@/views/memberManager/tier/index.vue'),
        meta: { name: i18n.t('menu.memberTier'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'tierList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'tierList',
            component: () => import('@/views/memberManager/tier/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 新增
            path: 'add',
            name: 'tierAdd',
            component: () => import('@/views/memberManager/tier/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'tierEdit',
            component: () => import('@/views/memberManager/tier/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'tierDetail',
            component: () => import('@/views/memberManager/tier/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, {
        // user group
        path: '/memberManager/userGroup',
        component: () => import('@/views/memberManager/userGroup/index.vue'),
        meta: { name: i18n.t('menu.userGroup'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'userGroupList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'userGroupList',
            component: () => import('@/views/memberManager/userGroup/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 添加
            path: 'add',
            name: 'userGroupAdd',
            component: () => import('@/views/memberManager/userGroup/add/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'userGroupEdit',
            component: () => import('@/views/memberManager/userGroup/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 添加用户组成员
            path: 'addMembers',
            name: 'userGroupMembersAdd',
            component: () => import('@/views/memberManager/userGroup/userGroupMembers/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 用户组用户详情
            path: 'memberDetail/:id',
            name: 'userGroupMemberDetail',
            component: () => import('@/views/memberManager/member/detail/index.vue'),
            meta: { name: i18n.t('text.memberDetail'), keepAlive: false }
          }
        ]
      }

    ]
  }
]

export default memberManager
