
import i18n from '@/i18n/index'

const roomsManager = [
  {
    path: '/roomsManager',
    component: () => import('@/views/roomsManager/index.vue'),
    meta: { name: i18n.t('menu.rooms'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 房间列表
        path: '/roomsManager/room',
        component: () => import('@/views/roomsManager/room/index.vue'),
        meta: { name: i18n.t('menu.roomList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'roomList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'roomList',
            component: () => import('@/views/roomsManager/room/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 列表
            path: 'basicPolicyList/:hotel_room_id_eq?',
            name: 'policyList',
            component: () => import('@/views/roomsManager/policy/list/index.vue'),
            meta: { name: i18n.t('menu.policyList'), keepAlive: true, showDetail: false },
            children: [
              {
                // 详情
                path: 'detail/:id',
                name: 'policyDetail',
                component: () => import('@/views/roomsManager/policy/detail/index.vue'),
                meta: { name: i18n.t('text.detail'), keepAlive: false, showDetail: true }
              },
              {
                // 添加
                path: 'add',
                name: 'policyAdd',
                component: () => import('@/views/roomsManager/policy/add/index.vue'),
                meta: { name: i18n.t('text.add'), keepAlive: false, showDetail: true }
              },
              {
                // 编辑
                path: 'edit/:id',
                name: 'policyEdit',
                component: () => import('@/views/roomsManager/policy/edit/index.vue'),
                meta: { name: i18n.t('text.edit'), keepAlive: false, showDetail: true }
              },
              {
                // 日历
                path: 'calendar/:id',
                name: 'policyCalendarEdit',
                component: () => import('@/views/roomsManager/policy/calendar/index.vue'),
                meta: { name: i18n.t('text.edit'), keepAlive: false, showDetail: true }
              }
            ]
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'roomDetail',
            component: () => import('@/views/roomsManager/room/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 添加
            path: 'add',
            name: 'roomAdd',
            component: () => import('@/views/roomsManager/room/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'roomEdit',
            component: () => import('@/views/roomsManager/room/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          }
        ]
      }
      // {
      //   // 政策列表
      //   path: '/roomsManager/policy',
      //   component: () => import('@/views/roomsManager/policy/index.vue'),
      //   meta: { name: i18n.t('menu.policyList'), allowBreadcrumbRedirect: true },
      //   redirect: {
      //     name: 'policyList'
      //   },
      //   children: [
      //     {
      //       // 列表
      //       path: 'list',
      //       name: 'policyList',
      //       component: () => import('@/views/roomsManager/policy/list/index.vue'),
      //       meta: { name: i18n.t('text.list'), keepAlive: true }
      //     },
      //     {
      //       // 详情
      //       path: 'detail/:id',
      //       name: 'policyDetail',
      //       component: () => import('@/views/roomsManager/policy/detail/index.vue'),
      //       meta: { name: i18n.t('text.detail'), keepAlive: false }
      //     },
      //     {
      //       // 添加
      //       path: 'add',
      //       name: 'policyAdd',
      //       component: () => import('@/views/roomsManager/policy/add/index.vue'),
      //       meta: { name: i18n.t('text.add'), keepAlive: false }
      //     },
      //     {
      //       // 编辑
      //       path: 'edit/:id',
      //       name: 'policyEdit',
      //       component: () => import('@/views/roomsManager/policy/edit/index.vue'),
      //       meta: { name: i18n.t('text.edit'), keepAlive: false }
      //     },
      //     {
      //       // 日历
      //       path: 'calendar/:id',
      //       name: 'policyCalendarEdit',
      //       component: () => import('@/views/roomsManager/policy/calendar/index.vue'),
      //       meta: { name: i18n.t('text.edit'), keepAlive: false }
      //     }
      //   ]
      // }
    ]
  }
]

export default roomsManager
