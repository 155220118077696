import i18n from '@/i18n/index'

const notificationManager = [
  {
    path: '/notificationManager',
    component: () => import('@/views/notificationManager/index.vue'),
    meta: { name: i18n.t('menu.notifications'), allowBreadcrumbRedirect: false },
    children: [
      {
        path: '/notificationManager/notification',
        component: () => import('@/views/notificationManager/notification/index.vue'),
        meta: { name: i18n.t('menu.notificationList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'notificationManagerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'notificationManagerList',
            component: () => import('@/views/notificationManager/notification/list/index.vue'),
            meta: { name: i18n.t('text.list') }
          },
          {
            // 添加
            path: 'add',
            name: 'notificationAdd',
            component: () => import('@/views/notificationManager/notification/add/index.vue'),
            meta: { name: i18n.t('text.add') }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'notificationDetail',
            component: () => import('@/views/notificationManager/notification/detail/index.vue'),
            meta: { name: i18n.t('text.detail') }
          }
        ]
      }
    ]
  }
]

export default notificationManager
