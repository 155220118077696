import i18n from '@/i18n/index'

const couponManager = [
  {
    path: '/couponManager',
    component: () => import('@/views/couponManager/index.vue'),
    meta: { name: i18n.t('menu.coupons'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 线上优惠券
        path: '/couponManager/onlineCoupon',
        component: () => import('@/views/couponManager/onlineCoupon/index.vue'),
        meta: { name: i18n.t('menu.onlineCoupon'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'couponManagerOnlineCouponList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'couponManagerOnlineCouponList',
            component: () => import('@/views/couponManager/onlineCoupon/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'couponManagerOnlineCouponEdit',
            component: () => import('@/views/couponManager/onlineCoupon/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'couponManagerOnlineCouponDetail',
            component: () => import('@/views/couponManager/onlineCoupon/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 编辑
            path: 'add',
            name: 'couponManagerOnlineCouponAdd',
            component: () => import('@/views/couponManager/onlineCoupon/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 商品列表
            path: 'productList',
            name: 'couponProductList',
            component: () => import('@/views/couponManager/onlineCoupon/activityProduct/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          }
        ]
      },
      {
        // 线下优惠券
        path: '/couponManager/offlineCoupon',
        component: () => import('@/views/couponManager/offlineCoupon/index.vue'),
        meta: { name: i18n.t('menu.offlineCoupon'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'offlineCouponList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'offlineCouponList',
            component: () => import('@/views/couponManager/offlineCoupon/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'offlineCouponEdit',
            component: () => import('@/views/couponManager/offlineCoupon/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'offlineCouponDetail',
            component: () => import('@/views/couponManager/offlineCoupon/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 编辑
            path: 'add',
            name: 'offlineCouponAdd',
            component: () => import('@/views/couponManager/offlineCoupon/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          }
        ]
      },
      {
        // 线上优惠券兑换
        path: '/couponManager/onlineCouponRedeem',
        component: () => import('@/views/couponManager/onlineCouponRedeem/index.vue'),
        meta: { name: i18n.t('menu.onlineCouponRedeem'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'onlineCouponRedeemList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'onlineCouponRedeemList',
            component: () => import('@/views/couponManager/onlineCouponRedeem/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'onlineCouponRedeemDetail',
            component: () => import('@/views/couponManager/onlineCouponRedeem/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 线下优惠券兑换
        path: '/couponManager/offlineCouponRedeem',
        component: () => import('@/views/couponManager/offlineCouponRedeem/index.vue'),
        meta: { name: i18n.t('menu.offlineCouponRedeem'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'offlineCouponRedeemList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'offlineCouponRedeemList',
            component: () => import('@/views/couponManager/offlineCouponRedeem/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'offlineCouponRedeemDetail',
            component: () => import('@/views/couponManager/offlineCouponRedeem/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'offlineCouponSnapshotsDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 优惠券礼包
        path: '/couponManager/voucherPack',
        component: () => import('@/views/couponManager/voucherPack/index.vue'),
        meta: { name: i18n.t('menu.voucherPack'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'voucherPackList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'voucherPackList',
            component: () => import('@/views/couponManager/voucherPack/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'voucherPackEdit',
            component: () => import('@/views/couponManager/voucherPack/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'voucherPackDetail',
            component: () => import('@/views/couponManager/voucherPack/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 新增
            path: 'add',
            name: 'voucherPackAdd',
            component: () => import('@/views/couponManager/voucherPack/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          }
        ]
      },
      {
        // 线上优惠券码
        path: '/couponManager/onlineVoucherCode',
        component: () => import('@/views/couponManager/onlineVoucherCode/index.vue'),
        meta: { name: i18n.t('menu.onlineVoucherCode'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'onlineVoucherCodeList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'onlineVoucherCodeList',
            component: () => import('@/views/couponManager/onlineVoucherCode/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'onlineVoucherCodeEdit',
            component: () => import('@/views/couponManager/onlineVoucherCode/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'onlineVoucherCodeDetail',
            component: () => import('@/views/couponManager/onlineVoucherCode/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 编辑
            path: 'add',
            name: 'onlineVoucherCodeAdd',
            component: () => import('@/views/couponManager/onlineVoucherCode/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 商品列表
            path: 'onlineVoucherCodeProductList',
            name: 'onlineVoucherCodeProductList',
            component: () => import('@/views/couponManager/onlineVoucherCode/activityProduct/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default couponManager
