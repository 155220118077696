export default {
  language: '中文',
  message: {
    hello: '你好，世界',
    greeting: '早上好',
    operateSuccess: '操作成功!',
    downSuccess: '下载成功',
    exportSuccess: '导出成功!',
    exportTaskSuccess: '导出任务创建成功，请到批量任务管理页面查看进度。',
    importSuccess: '导入完成!',
    quitSuccess: '退出成功!',
    loadSuccess: '加载完成!',
    loginSuccess: '登录成功!',
    quitMsg: '确认退出吗？',
    isnull: '不能为空',
    whetherToContinue: '此操作将导入文件{name}，是否继续？',
    loading: '加载中',
    uploadMessageSize: '上传文件大小不能超过 {num}MB!',
    uploadMessageType: '上传文件只能是 {type} 格式!',
    formIsRequire: '请检查表单，是否存在必填项未填写！',
    uploadFile: '上传文件',
    audioSupport: '您的浏览器不支持视频播放',
    newTab: '在新标签中打开链接',
    dataTip: '开始日期不能大于结束日期',
    twoPasswordDiffrent: '两次密码输入不一致',
    sentSuccess: '发送成功',
    synchronizationStarted: '同步请求已提交，请稍后刷新页面查看同步结果',
    compare: '不能大于',
    onlyOnePicture: '只能上传一张图片!',
    failedDelete: '删除失败！',
    uploadFailed: '上传失败！',
    pictureFormat: '只能上传JPG或png格式的图片！',
    imageExceed: '上传图片大小不超过3M!',
    iamgeNote: '注意：图片应为JPG、JPEG或PNG格式，大小不超过3M。',
    stringLimit: '仅支持大小写字母、数字和空格！',
    ifAdded: '是否要将SKU的自定义属性添加到商品？',
    imgRequire: '图片未上传',
    compareNotLess: '不能小于',
    compareNotLessEqual: '不能小于等于',
    redemptionCodeErrorMsg: '{name}必须是{num}个字符',
    positiveInter: '{name}只能为正整数',
    positiveInterMaxTwo: '{name}只能为正整数,最多两位小数',
    positiveInterMaxTwoZero: '{name}只能为非负正整数,最多两位小数',
    nonnegativePositiveInter: '{name}必须是非负正整数'
  },
  button: {
    search: '搜索',
    cancel: '取消',
    createProduct: '新建商品',
    import: '导入',
    export: '导出',
    batchImport: '批量导入',
    batchExport: '批量导出',
    view: '查看',
    edit: '编辑',
    delete: '删除',
    copy: '复制',
    batchOperate: '批处理',
    closebig: '关 闭',
    cancelbig: '取 消',
    determine: '确 认',
    reset: '重置',
    salesReport: '销售报表',
    freightList: '运费清单',
    orderExport: '订单导出',
    batchProcessing: '批处理',
    quit: '退出',
    confirm: '确定',
    logIn: '登录',
    resetPassword: '重置密码',
    moveToTop: '置顶',
    add: '添加',
    forceBtn: '强制更新',
    next: '下一步',
    wholesaPriceImport: '批量导入批发价',
    removeFromGroup: '从用户组删除',
    exportConsumeStatistics: '会员消费统计'
  },
  placeholder: {
    select: '{name}',
    defaultSelect: '请选择',
    selectTime: '请选择开售日期',
    purchaseCeiling: '请输入整数，无上限则不填(或填0)',
    maximumInventory: '该商品可卖出的最大数量',
    alertInventory: '低于此库存时将发送提醒',
    likeMinCount: '如果该喜好不是必选，请输入0',
    likeMaxCount: '如果没有限制，则不填',
    initialSales: '推荐商品中，初始销量越多越靠前',
    showRanking: '请输入一个整数',
    displayOrder: '请输入一个整数',
    input: '{name}',
    defaultInput: '请输入',
    password: '请输入密码',
    newPassword: '请输入新密码',
    newPasswordConfirmation: '请再次输入新密码',
    mail: '请输入邮箱',
    rightMail: '请输入正确的邮箱',
    discount: '0~100(举例:若填写80，即折扣价为原价的80%)',
    startDate: '开始日期',
    endDate: '结束日期'
  },
  text: {
    yes: '是',
    no: '否',
    selected: '已选择',
    tips: '提示',
    confirmation: '确认',
    to: '至',
    require: '{name}必填',
    required: '此项必填',
    confirmDeleteThisItem: '确定删除该条数据？',
    product: '商品',
    cnName: '中文名称',
    enName: '英文名称',
    online: '上架',
    enterCorrectAmount: '请输入正确的金额',
    enterCorrectPercentage: '请输入正确的百分数',
    enterCorrectNumber: '请输入正确的数字',
    image: '图片',
    add: '新增',
    edit: '编辑',
    detail: '详情',
    list: '列表',
    couponGiftOrderDetail: '优惠券礼包订单详情',
    offlineCouponOrderDetail: '线下优惠券订单详情',
    basicInfo: '基础信息',
    incompleteInformation: '信息未填写完整',
    batchOnline: '批量上架',
    batchOffline: '批量下架',
    batchDestroy: '批量删除',
    confirmBatchOnline: '确定批量上架？',
    confirmBatchOffline: '确定批量下架？',
    confirmBatchDestroy: '确定批量删除？',
    doubleConfirm: '二次确认',
    warning: '警告',
    buddy: '团长',
    name: '名称',
    type: '类型',
    symbol: '：',
    refrigeratedProduct: '冷藏品',
    frozenProduct: '冷冻品',
    basicSettings: '基础设置',
    productManagement: '产品管理',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sriday: '周日',
    groupSetting: '用户组设置',
    members: '添加用户',
    man: '男',
    female: '女',
    managerAddress: '管理地址',
    firstRequired: '第一项必填',
    memberDetail: '用户详情',
    memberAddressEdit: '会员地址编辑',
    memberAddressDetail: '会员地址详情',
    memberAddressAdd: '会员地址添加',
    lastSynchronizationTime: '上次同步时间: '
  },
  login: {
    rememberMe: '记住我',
    forgetPWD: '忘记密码？'
  },
  menu: {
    dataKanban: '数据看板',
    commodityManagement: '商品管理',
    productList: '商品列表',
    orderManagement: '订单管理',
    orderDeliver: '配送订单',
    afterSaleOrder: '售后订单',
    breakDownOrders: '拆分订单',
    userOrder: '用户订单',
    productEdit: '编辑商品',
    productCopy: '复制商品',
    productView: '商品详情',
    orderDetail: '{name}详情',
    downloadCenter: '下载中心',
    promotions: '营销中⼼',
    campaigns: '营销活动',
    addActivity: '新增活动',
    addActivityProduct: '新增活动商品',
    editActivity: '编辑活动',
    editActivityProduct: '编辑活动商品',
    shopCategories: '商品类别',
    shopSubCategories: '商品子类别',
    shopItemLabels: '商品标签',
    shopItemStock: '商品型号',
    buddyManagement: '团长管理',
    buddyList: '团长列表',
    buddyAdreess: '团长地址',
    buddyAdreessType: '地址类型',
    buddyCommission: '团长提成',
    buddyOCApplication: '团长开关店申请',
    buddyApplication: '团长申请',
    buddyWithdrawApplication: '团长提现申请',
    rewardsStore: '积分商城',
    rewardsProducts: '积分商品',
    productCategories: '商品类别',
    storesManager: '商店管理',
    shopsManager: '店铺',
    decoration: '页面管理',
    banner: '广告栏管理',
    popUps: '弹窗管理',
    navigation: '导航栏管理',
    coupons: '优惠券管理',
    onlineCoupon: '线上优惠券',
    offlineCoupon: '线下优惠券',
    onlineCouponRedeem: '线上优惠券兑换',
    attractions: '景点管理',
    attractionsList: '景点列表',
    tickets: '门票管理',
    bundleManager: '捆绑促销',
    bundles: '套餐',
    bundleProduct: '套餐商品',
    onlineVoucherCode: '线上优惠码活动',
    siteLabels: '景点标签',
    activityProduct: '商品',
    markettingCenter: '营销中心',
    flexiCombo: '多件多折',
    suppliersManager: '供货商管理',
    suppliers: '供货商',
    members: '会员管理',
    memberList: '会员列表',
    memberAddress: '会员地址',
    pointsRecord: '积分记录',
    memberTier: '会员等级',
    notifications: '通知管理',
    notificationList: '通知列表',
    offlineCouponRedeem: '线下优惠券兑换',
    voucherPack: '优惠券礼包',
    luckyDraw: '抽奖管理',
    prizeManagement: '奖品管理',
    participationRecords: '抽奖记录',
    winningRecords: '中奖记录',
    luckyDrawChance: '抽奖机会',
    userGroup: '用户组',
    customizedPage: '定制页面',
    setting: '系统设置',
    clients: 'Client端设置',
    zooTicketsManager: '门票管理',
    ticketPackage: '景区门票',
    packageType: '门票分类',
    globaltix_reseller_id: 'Globaltix分销商ID',
    ticketOrder: '门票订单',
    tPackage: '门票套票',
    hotSearch: '热门搜索',
    countries: '国家',
    addTicket: '新增门票',
    multiParkTickets: '多园票',
    adminUser: '管理员',
    referralManager: '邀请好友',
    rooms: '房型管理',
    roomList: '房型列表',
    policyList: '政策列表',
    hotels: '酒店管理',
    hotelList: '酒店列表',
    hotelFacility: '酒店设施',
    hotelLabel: '酒店标签',
    hotelOrders: '酒店订单',
    hotelAddons: '酒店附加服务',
    restOrder: '酒店订单',
    roomsSetting: '房间管理',
    contentManagement: '内容管理',
    hotelStatus: '房价房态',
    dineinOrders: '堂食订单',
    couponOrders: '优惠券订单',
    couponBundleOrders: '优惠券礼包订单',
    informationManagement: '信息管理',
    restaurantList: '餐厅列表',
    campaignList: '活动列表',
    retailList: '购物列表',
    experienceList: '体验列表',
    promotionList: '优惠列表',
    showList: '演出列表'
  },
  form: {
    common: {
      changeHistory: '变更历史',
      operator: '操作者',
      changeTime: '变更时间',
      creationTime: '创建时间',
      shop: '店铺',
      status: '状态',
      rank: '显示排名',
      longitude: '经度',
      latitude: '纬度',
      address: '地址',
      name: '名称',
      cnName: '中文名称',
      enName: '英文名称',
      shortDescription: '简要描述',
      shortDescriptionCn: '简要描述 (中文)',
      shortDescriptionEn: '简要描述 (英文)',
      description: '描述',
      descriptionCn: '描述 (中文)',
      descriptionEn: '描述 (英文)',
      titleCn: '标题 (中文)',
      titleEn: '标题 (英文)',
      contentCn: '内容 (中文)',
      contentEn: '内容 (英文)',
      operationHoursCN: '营业时间 (中文)',
      operationHoursEN: '营业时间 (英文)',
      language: '语言',
      file: '文件',
      image: '图片',
      rating: '评分',
      startTime: '开始时间',
      endTime: '结束时间',
      price: '网络价（元）',
      cost: '成本价（元）',
      basicPrice: '挂牌价（元）',
      refundable: '是否可退票',
      theLatestBookingTime: '当日最晚预订时间',
      validDaysOfWeek: '星期范围',
      mail: '邮箱',
      startDate: '开始日期',
      endDate: '结束日期',
      ticket: '门票',
      count: '数量',
      remark: '备注',
      video: '视频',
      link: '链接',
      userType: '用户类型',
      retail: '零售',
      wholesale: '批发',
      displayNameCn: '显示名称 (中文)',
      displayNameEn: '显示名称 (英文)',
      lontip: '-180 到 180',
      lattip: '-90 到 90',
      introductionCn: '简介 (中文)',
      introductionEn: '简介 (英文)'
    },
    rest: {
      city: '城市',
      area: '显示区域',
      address: '详细地址',
      website: '官方网址',
      phoneNumber: '联系电话',
      email: '邮箱',
      restPolicy: '酒店政策',
      restList: '所属酒店',
      defaultPrice: '默认价格($)',
      maxSize: '最多人数',
      price: '价格 ($)',
      prevWeek: '前一周',
      nextWeek: '下一周',
      showKinds: '展示类别',
      foodRecommendation: '美食推荐',
      travelGuide: '游玩攻略',
      monday: '周一',
      tuesday: '周二',
      wednesday: '周三',
      thursday: '周四',
      friday: '周五',
      saturday: '周六',
      sunday: '周日',
      rest: '酒店',
      roomAndType: '房间/房型',
      changeRoomStatus: '更改房态',
      changePrice: '修改售价',
      available: '可订',
      maintenance: '维护',
      closed: '关房',
      to_check_in: '待入住',
      checked_in: '已入住',
      restType: "房型",
      restRoom: "房间"
    },
    product: {
      basicMsg: '基本信息',
      online: '上架',
      recommend: '推荐',
      searchable: '可搜索',
      freeDelivery: '订单免邮',
      onlineTime: '上架时间',
      shop: '店铺',
      supplier: '供应商',
      shopType: '商品类型',
      shopCategories: '商品类别',
      shopSubCategories: '商品子类别',
      cnName: '中文名称',
      enName: '英文名称',
      maximumLimitPerUser: '同一用户购买上限',
      initSale: '初始销量',
      shortDescriptionCn: '简要描述 (中文)',
      shortDescriptionEn: '简要描述 (英文)',
      descriptionCn: '描述(中文)',
      descriptionEn: '描述(英文)',
      globalLabels: '全局标签',
      featuredTags: '特色标签',
      createFeaturedTags: '新建一个特色标签',
      image: '图片',
      createImage: '新建一个图片',
      imageFiles: '图片文件',
      buyNotices: '购买须知',
      addNotices: '添加须知',
      titleCn: '标题 (中文)',
      titleEn: '标题 (英文)',
      contentCn: '内容 (中文)',
      contentEn: '内容 (英文)',
      shopStock: '商品型号',
      createStock: '新建一个商品型号',
      priceUnit: '价格（$）',
      costPriceUnit: '成本价（元）',
      linePriceUnit: '划线价（元）',
      slaingStock: '可销售库存',
      warningStock: '预警库存',
      lockingStock: '库存量',
      preferences: '商品喜好',
      createPreferences: '新建一个喜好',
      title: '标题',
      displayOrder: '显示顺序',
      minCount: '最小选择数量',
      maxCount: '最大选择数量',
      preferenceOptions: '喜好选项',
      createPreferOptions: '新建一个喜好选项',
      name: '名称',
      price: '价格',
      sku: 'SKU ID',
      sellingPrice: '销售价（$）',
      costPrice: '成本价（元）',
      linePrice: '划线价（$）',
      headCommission: '团长提成 (%)',
      saleableInventory: '可销售库存',
      warningInventory: '预警库存',
      lockInventory: '锁定库存',
      compulsoryItem: '必买商品',
      categories: '类别',
      subCategories: '子类别',
      selectASubcategory: '请选择子类别',
      selectACategory: '请选择类别',
      exclusiveGoods: '独家商品',
      regularSale: '定时开售',
      specialProperties: '特别属性',
      invisibleLabel: '不可见标签',
      recommendedSize: '建议尺寸',
      offlineCoupons: '线下优惠券',
      commodityInventory: '商品库存',
      sevenDaysReturn: '支持售后',
      zone: 'Zone',
      section: 'Section',
      bin: 'Bin',
      rankingPriority: '显示排名',
      parentCategory: '父类别',
      sitePackInfo: '景点套票',
      stockTaking: '清点库存',
      ticket: '门票',
      show: '演出',
      hotelRoom: '酒店房间',
      offlineCoupon: '线下优惠券',
      ticketPackage: '套票',
      voucherPack: '优惠券礼包',
      afterSalesWindow: '售后窗口',
      ranking: '显示排名',
      invisibleFor: '以下用户组不可见',
      supportWholesale: '支持批发',
      wholesalePrice: '批发价格',
      directDeliveryHostCommission: '配送到家团长提成(%)',
      retail: '零售',
      wholesale: '批发',
      retailAndwholesale: '零售和批发',
      allowMultiple: '允许多选',
      nonHalal: '非清真',
      productDetail: '商品详情',
      productDisplay: '商品展示',
      label: '标签'
    },
    order: {
      userOrderDetails: '用户订单详情',
      userDeliverDetails: '配送订单详情',
      orderNumber: '订单号',
      totalAmount: '总金额',
      discountInformation: '优惠信息',
      paymentMethod: '支付方式',
      dsTime: '配送/自提时间',
      customerMsg: '客户信息',
      order: '订单',
      orderInfo: '订单信息',
      custInfo: '客户信息',
      deliverInfo: '配送信息',
      amountInfo: '金额信息',
      items: '项目',
      receiverInfo: '收货人信息',
      hostInfo: '团长信息',
      collectionInfo: '收藏信息',
      couponInfo: '优惠券信息',
      logisticInfo: '物流信息',
      productName: '商品名称',
      sku: 'SKU',
      removeProducNotice: '注意: 移除商品后，对应退款需要人工处理',
      currentCount: '当前数量',
      romveCount: '移除数量',
      romoveProduct: '移除商品',
      productCountLessThanOne: '商品数量小于1了',
      ticketName: '门票订单号',
      salesAmount: '销售额',
      costAmount: '成本',
      ticketing: '已出票门票',
      contactInfo: '合同信息',
      ticketDate: '选择日期',
      ticketTime: '选择时间',
      totalTax: '{gst}%消费税',
      totalPriceExcludingTax: '商品金额',
      totalTaxAmount: '总计（包含{gst}%消费税)'
    },
    activity: {
      basicActivitySettings: '基础活动设置',
      activityProductManagement: '活动产品管理',
      isOnline: '是否上架',
      online: '上架',
      offline: '下架',
      homePageDisplay: '是否首页显示',
      activityName: '活动名称',
      activityNameEN: '活动名称(英文)',
      activityType: '活动类型',
      activityTime: '活动时间',
      startTime: '开始时间',
      endTime: '结束时间',
      activityCover: '活动图片',
      deliveryTime: '送货日期',
      flashSaleSessions: '秒杀活动场次',
      sessions: '场次',
      remove: '清除',
      addFlashSaleSessions: '新增秒杀活动场次',
      nextStep: '下一步',
      productName: '商品名称',
      cover: '封面',
      shop: '商店',
      supplier: '供应商',
      participatingModel: '参与型号',
      model: '型号',
      price: '价格',
      addActivityProduct: '新增活动商品',
      activityProduct: '活动商品',
      freePostage: '订单免邮',
      purchaseLimitOfTheSameUser: '同一用户购买上限',
      minimumGroupSize: '最小拼团人数',
      timeWindow: '时间窗口(分钟)',
      startEndTime: '起止时间',
      submitShopActivitiesItemsWarning1: '至少选择一个型号参与活动',
      submitShopActivitiesItemsWarning2: '请填写完整参与活动型号的价格',
      chooseProduct: '选择活动商品',
      submitProductListDialogWarning1: '请选择一件商品',
      sameUserPurchaseLimit: '同一用户购买上限',
      slashSaleCount: '砍价次数',
      logisticInfo: '物流信息',
      sku: 'SKU',
      removeProducNotice: '注意: 移除商品后，对应退款需要人工处理',
      currentCount: '当前数量',
      romveCount: '移除数量',
      romoveProduct: '移除商品',
      productCountLessThanOne: '商品数量小于1了'
    },
    buddy: {
      addressType: '地址类型',
      houseNumber: '门牌号',
      officeBuilding: '写字楼',
      zipCode: '邮编',
      deliveryPrompt: '提货提示',
      paymentInformation: '支付信息',
      buddyType: '团长类型',
      buddyCode: '团长代码',
      buddyPSW: '团长密码',
      buddyName: '团长姓名',
      mobile: '手机电话',
      whatsAppNum: 'WhatsApp号码',
      wechatNum: '微信号码',
      icFrontPhoto: 'IC正面照片',
      icBackPhoto: 'IC背面照片',
      record: '记录',
      buddyMobile: '团长手机号',
      royaltyAmount: '提现金额 (元)',
      remarks: '备注',
      supportRefrigeratedProducts: '支持冷藏产品',
      supportFrozenProducts: '支持冷冻产品',
      supportHalalProducts: '支持清真商品',
      halalProductsOnly: '限清真产品',
      zeroCommission: '零佣金',
      whatsappGroupLink: 'Whatsapp群组链接',
      bankName: '银行名',
      bankSwiftCode: '银行代码',
      accountName: '用户名',
      accountNumber: '帐号',
      paynowNumber: 'Paynow 号码',
      roadName: '街道名称',
      blockNumber: '区域号'
    },
    rewardsStore: {
      rewardsProductType: '类型',
      sellingPoints: '销售积分'
    },
    store: {
      paymentInformation: '支付信息',
      settlementInfo: '结算信息',
      chargeGSTSeparately: '额外加收GST',
      official: '自营',
      automatedOrderProcessing: '自动化订单流程',
      smsNotification: '短信通知',
      smsNotificationNumber: '短信通知号码',
      itemTypes: '可售商品类型',
      adminUser: '管理员',
      logo: '图标',
      operationTimeStart: '营业开始时间',
      operationTimeEnd: '营业结束时间',
      phoneNumber: '联系电话',
      email: '商家邮箱',
      whatsAppNumber: 'WhatsApp号码',
      paynowWechat: '微信号',
      shopRuleszh: '店铺规则(中文)',
      shopRulesen: '店铺规则(英文)',
      shopCategories: '店铺标签',
      displayInformation: '页面显示',
      hideSoldCount: '隐藏已售数据',
      shopDisplayType: '店铺展示类型',
      productSortingRule: '商品排序规则',
      tabChoice: 'Tab选项',
      websiteInformation: '网页相关信息',
      shopSubDomain: '店铺域名',
      themeMode: '主题模式',
      themeColor: '主题色',
      deliveryInformation: '标准配送设置',
      deliveryZoneSettings: '区域配送设置',
      supportDelivery: '支持标准配送',
      minDeliveryAmount: '起送金额',
      freeDeliveryAmount: '免运费消费',
      deliveryDateDisplayMode: '日期显示模式',
      numberOfDays: '天数',
      cutoffDate: '截止日期',
      shopNoDeliveryDate: '暂停配送日期',
      startDate: '开始时间',
      endDate: '结束时间',
      deliveryTimeSlot: '标准配送时间段',
      deliveryTipszh: '配送须知(中文)',
      deliveryTipsen: '配送须知(英文)',
      deliveryFeeCalculationMethod: '店铺运费计算模式',
      deliveryFeebyDistance: '距离运费',
      deliveryFeebyOrderAmount: '金额运费',
      thirdPartyDelivery: '第三方配送',
      partnersEmail: '合作方邮箱',
      instructionforDriver: '司机须知',
      foodacornExpress: '橡果铺配送',
      grabExpress: 'Grab配送',
      keepDeliveryFee: '代收配送费',
      selfPickupInformation: '自提相关信息',
      supportSelfPickup: '支持自提',
      offsetMin: '间隔时间（分）',
      pickUpAdvanceTimeMin: '截单时间（分）',
      pickUpDateDisplayRange: '日期显示模式',
      mondaySelfPickupSlots: '周一自提时间段配置',
      tuesdaySelfPickupSlots: '周二自提时间段配置',
      wednesdaySelfPickupSlots: '周三自提时间段配置',
      thursdaySelfPickupSlots: '周四自提时间段配置',
      fridaySelfPickupSlots: '周五自提时间段配置',
      saturdaySelfPickupSlots: '周六自提时间段配置',
      sundaySelfPickupSlots: '周日自提时间段配置',
      selfPickupTipszh: '自提须知(中文)',
      selfPickupRipsen: '自提须知(英文)',
      payNowType: 'PayNow支付',
      creditCartPayment: '信⽤卡⽀付',
      cashOnDelivery: '货到付款/到店付款',
      merchantGuarantee: '商家服务信息',
      settlementInformation: '清算相关信息',
      wechatFeeRate: '微信费率%',
      automaticPaynowFeeRate: '自动paynow费率%',
      generalCommissionRate: '全局费率%',
      platformCommissionRate: '平台费率%',
      creditCardsCommissionRate: '信用卡费率%',
      selfOperation: '自营',
      generalInformation: '基础信息',
      cutOffMin: '截单时间（分）',
      quota: '配送名额',
      warningQuota: '预警配送名额',
      deliveryWeekdays: '配送星期',
      surcharge: '额外费用',
      startDistanceInclusive: '最小距离 (千米)(包含)',
      endDistanceExclusive: '最大距离 (千米)(不包含)',
      startAmountInclusive: '最小金额 (包含)',
      endAmountExclusive: '最大金额 (不包含)',
      expressFee: '快递费',
      payNowQRCode: 'PayNow二维码',
      payNowUEN: 'PayNow UEN',
      payNowPhoneNumber: 'PayNow手机号',
      start: '开始时间',
      end: '结束时间',
      pickupCutoff: '截止日期',
      offlineShop: '门店',
      offlineShopDetails: '门店详情',
      operationHours: '营业时间',
      longitude: '经度',
      latitude: '纬度',
      address: '地址',
      entityInformation: '主体信息',
      offlineEntityType: '线下主体类型',
      offlineEntity: '线下主体',
      shippingPoint: '发货点',
      supportPickup: '支持自提',
      SMSRecord: '短信记录',
      categories: '店铺标签',
      stripeAccount: 'Stripe账号',
      serviceFee: '服务费（%）',
      deliveryZones: '配送区域',
      createDeliveryZone: '新建配送区域',
      online: '上架',
      zoneNamecn: '区域名称(中文)',
      zoneNameen: '区域名称(英文)',
      postalCodeRange: '邮政编码范围',
      supportZoneDelivery: '支持区域配送',
      zoneDeliveryTimeSlot: '配送到家区域设置',
      suspendDeliveryTimeSlot: '暂停配送日期',
      suspendDeliveryStartDate: '开始日期',
      suspendDeliveryEndDate: '结束日期',
      validCommas: '请以英文逗号分隔',
      zoneName: '区域名称'
    },
    decoration: {
      banner: '广告名称',
      popups: '弹窗名称',
      navigation: '导航名称',
      beginDate: '开始时间',
      endDate: '结束时间',
      platform: '平台',
      promotionGroup: '广告位置',
      rankingPriority: '显示排名',
      mainTitle: '广告主标题',
      subtitle: '广告副标题',
      targetPage: '广告落地页',
      customLink: '自定义链接',
      tips: '广告备注',
      navigationType: '页面',
      navigationButton: '导航落地页',
      subtitlecn: '副标题(中文)',
      subtitleen: '副标题(英文)',
      CustomUrl: '自定义链接',
      image: '导航图片',
      ranking: '显示排名',
      naviNameCN: '导航名称',
      naviNameEN: '导航名称(英文)',
      thirdPartyService: '第三方配送',
      POSvendor: 'POS Vendor',
      deliveryVendor: 'Delivery Vendor',
      merchantId: 'Merchant Id',
      secretKey: 'Secret Key',
      clientId: 'Client Id',
      pageTitle: '页面名称',
      customizedPageElement: '定制页面页元素',
      pageName: '页面名称'
    },
    coupon: {
      online: '上架',
      objectType: '适用对象类型',
      client: 'C端',
      shop: '店铺',
      applicationObject: '适用对象',
      name: '名称',
      receiveType: '领取方式',
      deliveryType: '配送方式',
      type: '类型',
      discountAmount: '优惠金额(元)',
      useDoor: '适用门槛(元)',
      getTimes: '领取次数',
      stock: '库存',
      validityType: '有效期类型',
      startDate: '开始日期',
      endDate: '到期日期',
      simpleDsc: '简要描述',
      describe: '详细描述',
      instructions: '使用须知',
      img: '图片',
      validDays: '有效天数',
      onlineCouonDetail: '线上优惠券详情',
      all: '全部',
      offlineCouponDetail: '线上优惠券详情',
      eventName: '活动名称',
      codeStartDate: '开始日期',
      codeType: '优惠码类型',
      maximum: '最多使用次数',
      redemptionLimit: '相同用户可使用次数',
      voucherType: '优惠类型',
      voucherValue: '优惠金额(元)',
      appliedTargetType: '适用对象类型',
      chooseClient: '选择C端',
      chooseShop: '选择店铺',
      miniSpend: '使用金额门槛',
      codeGeneration: '优惠码生成方式',
      voucherCode: '优惠码',
      onlyNew: '仅限新人',
      chooseProduct: '选择商品',
      chooseOfflineCoupon: '选择线下优惠券',
      numberOfCodes: '生成个数',
      authShop: '授权店铺',
      price: '价格',
      couponDiscout: '折扣%',
      originalPrice: '原价',
      maxDiscount: '最大折扣(元)',
      createProduct: '新增商品',
      selectProduct: '选择商品',
      dineIn: '适用堂食',
      applyTo: '适用商品',
      entireShop: '店铺所有商品',
      selectedProducts: '选择商品',
      couponName: '优惠券名称',
      voucherPackName: '优惠券礼包名称',
      useGuideCN: '使用说明 (CN)',
      useGuideEN: '使用说明 (EN)',
      PolicyCN: '使用条例 (CN)',
      PolicyEN: '使用条例 (EN)',
      bundleValue: '礼包价值',
      expirationInDays: '有限期（天）',
      bundledCoupon: '礼包优惠券',
      quantity: '数量',
      coupon: '优惠券',
      chooseSite: '选择景点',
      chooseTicket: '选择门票',
      entireTicket: '全部门票',
      selectedTicket: '选择门票',
      ticketApplyTo: '适合门票',
      thirdPartyVoucherId: '第三方优惠码id',
      basePrice: '基准价格',
      basePriceText: '该设置仅对门票订单有效'
    },
    attractions: {
      redemptionCode: '兑换密码',
      site: '所属景点',
      siteALevel: '景点A级',
      city: '区县',
      website: '官方网址',
      contactNumber: '联系电话',
      recommendedVisitDuration: '推荐游玩时间(分钟)',
      ticketGuideCN: '门票攻略(中文)',
      ticketGuideEN: '门票攻略(英文)',
      transportationGuideCN: '交通攻略(中文)',
      transportationGuideEN: '交通攻略(英文)',
      remindersCN: '温馨提示(中文)',
      remindersEN: '温馨提示(英文)',
      ticketType: '预订类型',
      siteCategories: '景点分类',
      bestPrice: '最低价L（元）',
      specialLabel: '特色标签',
      audioFiles: '音频文件',
      imageFiles: '图片文件',
      subsite: '导览点',
      groupName: '分组',
      packInfoId: '景点门票',
      applicableSpectator: '适用人群',
      bookingTimeRule: '预订时间规则',
      validity: '有效期',
      serviceIncluded: '费用包含',
      useGuide: '使用方法',
      paperTicketRedemptionAddress: '换票地址',
      refundPolicy: '退票说明',
      purchasePolicy: '购买须知',
      onlyForToday: '仅当日可订',
      ticketPack: '景点门票',
      globaltixProduct: 'Globaltix对应产品',
      price: '网络价（元）',
      cost: '成本价（元）',
      basicPrice: '挂牌价（元）',
      refundable: '是否可退票',
      theLatestBookingTime: '当日最晚预订时间',
      validDaysOfWeek: '星期范围',
      siteName: '景点名称',
      mapMode: '地图模式',
      label: '标签',
      fileAttachment: '文件',
      delDate: 'Deleted at',
      ticketsI: '门票套餐',
      ticketCategoriesDetails: '门票分类信息',
      ticketDetails: '门票详情',
      ticketCategoriesList: '门票分类',
      ticketingSystem: '票务系统',
      ticketingSystemProduct: '绑定产品',
      ticketName: '门票名称'
    },
    bundle: {
      name: '套餐名称(中文)',
      name_en: '套餐名称(英文)',
      start_date: '开始日期',
      end_date: '结束日期',
      shortDescriptionCn: '简要描述(中文)',
      shortDescriptionEn: '简要描述(英文)',
      image: '图片',
      addImage: '添加图片',
      addBundle: '新建一个Bundle item',
      itemType: '套餐商品类型',
      itemProduct: '商品',
      itemDiscount: '优惠折扣',
      product: '商品',
      item: 'Shop item bundle',
      delDate: 'Deleted at',
      main: 'Must Buy Products',
      optional: 'Optional Products',
      detail: '套餐',
      coverImage: '图片',
      discountType: '优惠方式',
      discountPercent: '打折优惠',
      discountPercent2: '优惠折扣',
      discountValue: '金额优惠',
      discountValue2: '金额优惠',
      discountValue3: '优惠金额'
    },
    flexiCombo: {
      period: '有效期',
      details: '详情',
      name: '活动名称',
      entrieShop: '全部店铺',
      stackable_discount: '优惠可叠加',
      termsAndConditions: '使用说明',
      shopId: '店铺',
      requierQuantity: '如果订单数量达到',
      discountAmount: '折扣是',
      discount_type: '优惠类型',
      discountDetails: '优惠详情',
      startDate: '开始日期',
      endDate: '结束日期',
      deal_criteria: '优惠门槛',
      requierValue: '如果订单金额达到',
      total_number: '最多允许几单',
      discountApply: '是否应用到整个商店',
      stackableDiscount: '优惠上不封顶',
      selectProduct: '提交之后请选择商品',
      stackable: '例：满2SGD减1SGD，满4SGD减2SGD，满6SGD减3SGD，如此类推',
      percent_off: '折扣',
      money_value_off: '减钱',
      item_quantity: '满件',
      item_value: '订单金额达到门槛'
    },
    supplers: {
      name: '姓名',
      email: '邮箱',
      wechat: '微信号码',
      whatsapp: 'WhatsApp号码',
      phoneNumber: '联系电话',
      img: '图标'
    },
    members: {
      memberName: '会员名称',
      addressName: '会员地址名称',
      manualTopUp: '手动增加积分',
      manualDeduct: '手动扣除积分',
      batchUpdatePoints: '批量更新积分',
      rewardName: '记录名称',
      tiresName: '等级名称',
      benefits: '会员权益',
      memberCardImage: '会员卡图片',
      tierName: '等级名称',
      tierNameEN: '等级英文名称',
      minimumPoints: '最少积分',
      icon: '图标',
      name: '权益名称',
      nameEN: '权益英文名称',
      brief: '权益简介',
      briefEN: '权益英文简介',
      detail: '权益详情',
      detailEN: '权益英文详情',
      pointOperationType: '积分操作类型',
      userId: '用户id',
      remarks: '备注',
      topUpBy: '计算方式',
      topUpAmount: '积分数量',
      amountPaid: '消费金额',
      deductAmount: '扣除积分数量',
      deductBy: '计算方式',
      allIn: '用户id和用户手机必填其一',
      phoneNumber: '手机号码',
      pointOperation: '积分操作',
      addressee: '名称',
      address: '地址',
      postalCode: '邮编',
      unitNo: '门牌号',
      buildingName: '建筑名称',
      defaultAddress: '默认地址',
      addressType: '地址类型',
      residentialType: '地址种类',
      roadName: '街道名称',
      private: '私人',
      business: '商务',
      user: '用户/电话号码',
      blockNumber: '楼栋号',
      nickname: '昵称',
      userName: '姓名',
      avatar: '头像',
      gender: '性别',
      birthday: '生日',
      currBuddy: '当前团长',
      offlineShopMember: '线下门店会员',
      userType: '用户类型',
      addAddress: '添加地址',
      email: '邮箱',
      userGroup: '用户组',
      dateRange: '日期范围'
    },
    notifications: {
      pushType: '推送方式',
      scheduledTime: '推送时间',
      audience: '通知对象',
      title: '标题',
      subTitle: '副标题',
      content: '内容',
      buttonText: '按钮文字',
      buttonLink: '按钮落地页'
    },
    luckyDraw: {
      rankingPriority: '显示排名',
      prizeableType: '奖品类型',
      prizeableId: '奖品',
      probability: '概率',
      countRule: '0或不填代表数量不限',
      probabilityRule: '中奖概率千分比，范围0-1000, 填入1为0.1%, 填入1000为100%',
      id: '序号',
      reedCode: '兑换码'
    },
    groupUser: {
      name: '用户组名称',
      groupType: '用户组类型',
      priceTier: '价格层级',
      retail: '零售',
      wholesale: '批发',
      userName: '姓名',
      avatar: '头像',
      nickName: '昵称',
      gender: '性别',
      addMembers: '添加用户',
      selectMembers: '选择用户'
    },
    setting: {
      customerServiceName: '客服昵称',
      customerServiceLogo: '客服图标',
      customerServicePhoneNumber: '客服电话',
      defaultImage: '默认图片',
      noOfNavigationRows: '导航栏按钮排数',
      customerServiceWhatsApp: '客服WhatsApp号码',
      customerServiceEmail: '客服邮箱',
      emailsTips: '若需要⽀持多个邮箱请⽤分号(;)隔开',
      emailFromName: 'Email发送名字',
      officialAccountLink: '公众号关注链接',
      officialAccountName: '公众号名称',
      officialAccountIcon: '公众号图标',
      slashSaleWechatMomentImage: '砍价朋友圈背景图',
      groupBuyWechatMomentImage: '拼团朋友圈背景图',
      noticeCN: '通知 (中文)',
      NoticeEN: '通知 (英文)',
      emailAddress: '邮箱',
      googleAnalyticsTag: 'Google Analytics Tag',
      // clientType: 'Client Type',
      // moduleArray: 'Module Array',
      // turnOnWeChatEvent: 'Turn On WeChat Event',
      // turnOnCouponDistribution: 'Turn On Coupon Distribution',
      invoiceSetting: '发票配置',
      invoiceLanguage: '发票语言',
      languageCn: '中文',
      languageEn: '英文',
      companyName: '公司名称',
      companyRegNo: '公司营业执照号码',
      GSTRegNo: 'GST Reg. No.',
      companyAddress: '公司地址',
      companyPostalCode: '公司邮编',
      invoicePrefix: '发票号前缀',
      paymentSettings: '支付配置',
      pendingPaymentWindow: '待⽀付窗口（分）',
      // merchantId: 'Merchant Id',
      // paymentKey: 'Payment Key',
      // APICertificate: 'API Certificate',
      // APIKey: 'API Key',
      // kserMerchantId: 'Kser Merchant Id',
      // kserPrivateKey: 'Kser Private Key',
      // FomopayKey: 'Fomopay Key',
      // fomopayService: 'Fomopay Service',
      // fomopayMid: 'Fomopay Mid',
      // fomopayTid: 'Fomopay Tid',
      // apMid: 'Ap Mid',
      // apMerchantCode: 'Ap Merchant Code',
      // apPaynowMid: 'Ap Paynow Mid',
      // apPaynowTid: 'Ap Paynow Tid',
      // stripeApikey: 'Stripe Api Key',
      // attractPayMerchantId: 'AttractPay Merchant Id',
      // attractPayPrivateKey: 'AttractPay Private Key',
      // uniwebWechatOutlet: 'Uniweb Wechat Outlet',
      // uniwebWechatPartner: 'Uniweb Wechat Partner',
      // eghlAlipayMerchantId: 'Eghl Alipay Merchant Id',
      // eghlAlipayMerchantPassword: 'Eghl Alipay Merchant Password',
      // eghlFpxMerchantId: 'Eghl Fpx Merchant Id',
      // eghlFpxMerchantPassword: 'Eghl Fpx Merchant Password',
      inventorySettings: '库存配置',
      inventoryProvider: '库存供应商',
      buddySettings: '团长配置',
      minimumHostWithdrawAmount: '团长最低提现金额',
      loyaltySettings: '积分配置',
      accumulationRadio: '积分比例',
      accTips: '指代消费一元可以获得多少积分',
      redemptionRadio: '兑换比例',
      redTips: '指代多少积分可以抵扣一元',
      minimumPointsPerOrder: '每单最低使⽤积分',
      rules: '积分规则',
      termsInformation: '条款信息',
      termsAndConditions: '服务条款',
      privacyPolicy: '隐私声明',
      gameRule: '游戏规则',
      phone_number_code: '电话编码',
      ranking: '显示排名',
      countryCode: '国家编码',
      role: '角色',
      locale: '默认语言',
      password: '密码',
      cmpassword: '确认密码',
      permissionInfo: '权限信息',
      permissionType: '权限类型',
      permissionList: '权限列表'
    },
    zoo: {
      ticket_type: '门票分类',
      active: '上架',
      site: '景区',
      site2: '景区',
      ticket_package: '所属景区门票',
      ticket_package_id: '所属景区门票',
      name: '名称（中文）',
      name2: '名称（英文）',
      inclusions: '包含内容',
      peak_season: '高峰季节',
      visit_date_required: '日历票',
      advance_booking_in_minutes: '提前预定时间(分钟)',
      use_guide: '使用指南',
      max_pax: '限购数量',
      min_pax: '最低购买数量',
      capacity_control: '限量供应',
      description: '描述',
      short_description: '简要描述',
      terms_and_conditions: '用户条款',
      validity_in_days: '有效期(天)',
      local_residence_only: '仅适⽤于本地居民',
      cancellation_policies: '取消政策',
      cancellable: '可取消',
      paper_ticket_redemption_required: '需要换票',
      ranking: '显示排名',
      age_label: '年龄标签',
      last_synced_at: '最近同步时间',
      third_party_platform: '第三方平台',
      third_party_pid: '第三方平台产品ID',
      parent_ticket_type: '绑定父门票分类',
      original_price: '原价',
      listing_price: '售价',
      globaltix_ticket_id: 'globaltix门票',
      booking_time_desc: '预定时间',
      validity_desc: '有效期限',
      visitor_applicability: '适用人群',
      inclusion_desc: '费用包含',
      redemption_guide: '使用方法',
      redemption_address: '换票地址',
      cancellation_guide: '退票方法',
      additional_guide: '补充说明',
      t_description: '描述',
      settlement_price: '第三方结算价',
      payable_amount: '挂牌价',
      t_original_price: '第三方原价',
      thirdPartyInformation: '第三方信息',
      guideAndPolicies: '说明与政策',
      t_end_date: '第三方结束日期',
      t_start_date: '第三方开始日期',
      validDaysOfWeek: '星期范围',
      ticket_type2: '门票类型',
      tPackage: '套票信息',
      packageName: '套票名称',
      location: '地点',
      policyInformation: '政策信息',
      basicTicketSettings: '基础设置',
      manageTickets: '门票管理',
      pax: '必选园区数',
      site_ids: '园区',
      sharedCalendar: '共用日历',
      payableAmount: '第三方销售价',
      exceptionDates: '暂停日期',
      exceptionalDateRanges: '特殊日期',
      exceptional_type: '例外类型',
      allTickets: '所有门票',
      sync: '同步 PLUs',
      support_timeslot: '可选入园时间',
      admission_time: '入园时间'
    },
    hotSearch: {
      name: '关键字（中文）',
      name_en: '关键字（英文）',
      client: '客户端'
    },
    hotel: {
      openedAt: '开业时间',
      bookingType: '预订类型',
      commercialVicinity: '商圈',
      starRating: '酒店星级',
      paymentDesc: '扣款说明',
      bookingNotice: '预订须知',
      foreignerPolicies: '外宾情况',
      globalLabels: '分类标签',
      roomName: '房型名字',
      hotelName: '酒店',
      hotelRoom: '房间',
      policyName: '政策名字',
      price: '政策价格',
      unit: '单位：$',
      priceCalendar: '价格日历',
      price2: '价格',
      costPrice: '划线价',
      empty: '未设置',
      Managepolicies: '政策管理',
      stayDates: '⼊住⽇期',
      hotel: '酒店'
    },
    referral: {
      target: '链接',
      inviteRewardType: '接受者奖励类型',
      inviteReward: '接受者奖励',
      userRewardType: '邀请者奖励类型',
      userReward: '邀请者奖励',
      validDate: '有效期',
      coupon: '优惠券'
    },
    basicMessage: {
      supportChope: 'Chope预定',
      multipleChopeId: '多个Chope餐厅ID',
      chopeRestaurantID: 'Chope餐厅ID',
      chopeRestaurantName: 'Chope餐厅名',
      chopeID: 'Chope ID',
      averagePrice: '均价',
      homepageDisplay: '首页显示',
      deliveryStartDate: '开始送货日期',
      deliveryEndDate: '结束送货日期',
      dishes: '菜品',
      chopeMessage: 'Chope信息',
      poiType: '关联POI类型',
      poi: '关联POI',
      PromotionTimeCn: '优惠时间 (中文)',
      PromotionTimeEn: '优惠时间 (英文)',
      usage: '使用须知 (中文)',
      usage_en: '使用须知 (英文)',
      ticketGuide: '门票攻略 (中文)',
      ticketGuide_en: '门票攻略 (英文)',
      TicketType: '预订类型',
      ShowTime: '演出时间 (中文)',
      ShowTime_en: '演出时间 (英文)',
      RecommendedDuration: '推荐游玩时间 (分钟)',
      scenicSpot: '所属景点'
    }
  },
  table: {
    common: {
      operate: '操作',
      shop: '店铺',
      status: '状态'
    },
    product: {
      shop: '店铺',
      supplier: '供应商',
      name: '名称',
      basicInformation: '基本信息',
      productModel: '商品型号',
      status: '状态',
      pickupMethod: '取货方式',
      operate: '操作',
      form: {
        basicMsg: '基本信息',
        online: '上架',
        recommend: '推荐',
        searchable: '可搜索',
        freeDelivery: '订单免邮',
        onlineTime: '上架时间',
        shop: '店铺',
        supplier: '供应商',
        shopType: '商品类型',
        shopCategories: '商品类别',
        shopSubCategories: '商品子类别',
        cnName: '中文名称',
        enName: '英文名称',
        maximumLimitPerUser: '同一用户购买上限',
        initSale: '初始销量',
        shortDescriptionCn: '简要描述(中文)',
        shortDescriptionEn: '简要描述(英文)',
        descriptionCn: '描述(中文)',
        descriptionEn: '描述(英文)',
        globalLabels: '全局标签'
      }
    },
    order: {
      username: '用户',
      host: '团长',
      productDetail: '产品详情',
      shippingDetail: '运单详情',
      ordersDetail: '订单详情',
      deliveryDetail: '物流详情',
      orders: '订单数量',
      meetMoq: '满足最小起订量',
      bookingInfo: '预订信息',
      roomInfo: '房间信息',
      addons: '附加服务',
      payTime: '支付时间',
      adminRemark: '管理员留言',
      roomsCount: '房间数量'
    },
    activity: {
      addActivity: '新增活动',
      confirmDeleteActivity: '确定删除该活动？',
      participate: '参加活动',
      modifier: '型号',
      sellingPrice: '销售价',
      campaignPrice: '活动价',
      lowestPrice: '可砍最低价',
      groupBuyPrice: '拼团价',
      activityProductSellingPriceAndCampaignPrice: '原价: S$ {sellingPrice}, 活动价: S$ {campaignPrice}',
      activityProductSellingPrice: '原价: S$ {sellingPrice}'
    }
  },
  dashboard: {
    todayMission: '数据总览',
    userOrders: '用户订单',
    unconfirm: '待确认',
    unconfirm2: '待确认',
    undelivery: '今日待发货',
    cancling: '取消中',
    cancling2: '取消中',
    afterbuy: '售后待审核',
    grouporders: '团购配送订单',
    unMOQ: '尚未达到MOQ',
    totalInfo: '销售',
    open: '营收',
    more: '更多',
    realSell: '营业实收',
    income: '销售金额(元)',
    chongzhi: '充值金额(元)',
    totalOrders: '订单总数',
    mallOrders: '用户自提',
    deliveryOrders: '配送到家',
    otherOrders: '团长自提',
    product: '商品',
    paiming: '销量最佳商品',
    selling: '销量',
    user: '用户',
    thisMonth: '本月',
    unComfirm2: '待审核',
    allUser: '总用户',
    newUser: '新用户',
    oldUser: '老用户',
    turnoverChart: '营业额趋势图',
    orderChart: '订单数趋势图',
    orderChart2: '门票订单数趋势图',
    turnoverTitle: '商城总营收',
    orderTitle: '门票营收',
    get: '收',
    refund: '退',
    dan: '单',
    ge: '个',
    ticketOrders: '门票订单',
    ticketIncome: '门票收入',
    refunding: '退款中',
    paidToday: '今日已支付',
    fulfilledToday: '今日已完成',
    toDeliveryToday: '今日配送订单',
    salesToday: '今日配送订单收入',
    groupbuyAction: '团购操作',
    pendingHostApplications: '团长申请',
    pendingCashbackWithdrawal: '团长返现申请'
  },
  fooyoDashboard: {
    accountInfo: '账户信息',
    storeName: '店铺名称',
    licenceId: 'Licence ID',
    entityName: '主体名称',
    storeWebsite: '店铺网址',
    operationReport: '运营数据',
    shops: '店铺',
    dateRange: '日期',
    allShops: '所有店铺',
    newUserOrders: '新增用户订单',
    newUser: '新增用户',
    voucherRedemption: '优惠券兑换',
    voucherIssued: '已分发优惠券',
    voucherRedeemed: '已兑换优惠券',
    howOrders: '{count}单',
    orderFulfillment: '订单数据',
    ordersFulfilled: '已完成订单',
    salesAndOrdersFulfilled: '销售额与已完成订单',
    sales: '销售额',
    orderFulfilled: '已完成订单',
    averageBasketSize: '平均每单件数',
    topSellingProducts: '热销商品',
    userActivity: '活跃用户',
    activeUser: '活跃用户',
    inactiveUser: '不活跃用户',
    bySales: '按销售数量排序',
    byAmount: '按销售金额排序'
  }
}
