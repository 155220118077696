export default {
  language: 'ENlish',
  message: {
    operateSuccess: 'Operate Success!',
    exportSuccess: 'Export Success!',
    exportTaskSuccess: 'Export task created. Please proceed to "Batch Task" page to check the progress.',
    importSuccess: 'Import Success!',
    downSuccess: 'Download Success!',
    quitMsg: 'Are you sure to log out?',
    loadSuccess: 'Loaded!',
    quitSuccess: 'Exit Success!',
    loginSuccess: 'Login Success!',
    isnull: 'Cannot Be Empty',
    whetherToContinue: 'This action will import the file {name},Whether to continue?',
    loading: 'Loading',
    uploadMessageSize: 'Upload file size cannot exceed {num}MB!',
    uploadMessageType: 'Upload files can only be in {type} format!',
    formIsRequire: 'Please check the form to see if there are any required items that have not been filled out!',
    uploadFile: 'Upload File',
    audioSupport: 'Your browser does not support video playback',
    newTab: 'Open link in new tab',
    dataTip: 'The start date cannot be later than the end date',
    twoPasswordDiffrent: 'The passwords entered twice are inconsistent',
    sentSuccess: 'Sent Successfully',
    synchronizationStarted: 'The synchronization request has been submitted. Please refresh the page later to view the synchronization results',
    compare: ' cannot be greater than ',
    onlyOnePicture: 'Only one picture can be uploaded!',
    failedDelete: 'failed to delete!',
    uploadFailed: 'upload failed!',
    pictureFormat: 'The uploaded profile picture can only be in JPG or png format!',
    imageExceed: 'The size of the uploaded avatar image cannot exceed 3MB!',
    iamgeNote: 'Note: Pictures should be in JPG, JPEG or PNG format and with size not exceeding 3M.',
    stringLimit: 'Only uppercase and lowercase letters, numbers and spaces are supported!',
    ifAdded: 'Do you want to merge this SKU’s custom attributes into this product?',
    imgRequire: 'Image is required',
    compareNotLess: 'cannot be less than',
    compareNotLessEqual: 'Cannot be less than or equal to',
    redemptionCodeErrorMsg: '{name}can only be {num} characters',
    positiveInter: '{name} can only be positive integer',
    positiveInterMaxTwo: '{name} can only be positive integer,up to two decimal places',
    positiveInterMaxTwoZero: '{name} can only be a non-negative positive integer, with a maximum of two decimal places',
    nonnegativePositiveInter: '{name} can only be non negative positive integer'
  },
  button: {
    search: 'Filter',
    cancel: 'Cancel',
    createProduct: 'Create Product',
    import: 'Import',
    export: 'Export',
    batchImport: 'Batch Import',
    batchExport: 'Batch Export',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    copy: 'Copy',
    batchOperate: 'Batch operate',
    closebig: 'Close',
    cancelbig: 'Cancel',
    determine: 'Determine',
    reset: 'Clear Filters',
    salesReport: 'Sales Report',
    freightList: 'Delivery Fee Settlement',
    orderExport: 'Order Export',
    batchProcessing: 'Batch Processing',
    quit: 'Quit',
    confirm: 'Confirm',
    logIn: 'Log In',
    resetPassword: 'Reset Password',
    moveToTop: 'Move to Top',
    add: 'Add',
    forceBtn: 'Force Update',
    next: 'Next Step',
    wholesaPriceImport: 'Batch Import Wholesale Prices',
    removeFromGroup: 'Remove From Group',
    exportConsumeStatistics: 'Member consumption statistics'
  },
  placeholder: {
    select: '{name}',
    defaultSelect: 'Please select',
    selectTime: 'Please select the opening date',
    purchaseCeiling: 'Please enter an integer. If there is no upper limit, leave it blank (or 0)',
    maximumInventory: 'The maximum quantity that can be sold',
    alertInventory: 'Reminder will be sent when it is lower than this stock',
    likeMinCount: 'If this preference is not required, please enter 0',
    likeMaxCount: 'If there is no limit, do not fill in',
    initialSales: 'Among the recommended products, the more initial sales, the higher the ranking',
    showRanking: 'Please enter an integer',
    displayOrder: 'Please enter an integer',
    input: '{name}',
    defaultInput: 'Please Input',
    password: 'Please Input The Password',
    newPassword: 'Please Input The New Password',
    newPasswordConfirmation: 'Please Input The New Password Again',
    mail: 'Please Input The Email',
    rightMail: 'Please Input Your Vaild Email',
    discount: '0~100. (E.g. 80 means the price after discount is 80% of the original price)',
    startDate: 'Start Date',
    endDate: 'End Date'
  },
  text: {
    yes: 'Yes',
    no: 'No',
    selected: 'Selected',
    tips: 'Tips',
    confirmation: 'Confirmation',
    to: 'To',
    require: '{name} is required',
    required: 'This item is required',
    confirmDeleteThisItem: 'Are you sure to delete this item',
    product: 'Product',
    cnName: 'CNname',
    enName: 'ENname',
    online: 'Active',
    enterCorrectAmount: 'Please enter the correct amount',
    enterCorrectPercentage: 'Please enter the correct percentage',
    enterCorrectNumber: 'Please enter the correct number',
    image: 'Image',
    add: 'Create New',
    edit: 'Edit',
    detail: 'Detail',
    list: 'List',
    couponGiftOrderDetail: 'Coupon Gift Order Detail',
    offlineCouponOrderDetail: 'Offline Coupon Order Detail',
    basicInfo: 'Basic Information',
    incompleteInformation: 'Incomplete Information',
    batchOnline: 'Batch Activate',
    batchOffline: 'Batch Deactivate',
    batchDestroy: 'Batch Delete',
    confirmBatchOnline: 'Are you sure to batch activate these items ?',
    confirmBatchOffline: 'Are you sure to batch deactivate these items ?',
    confirmBatchDestroy: 'Are you sure to batch Delete these items ?',
    doubleConfirm: 'Double Confirm',
    warning: 'Warning',
    buddy: 'Host',
    name: 'Name',
    type: 'Type',
    symbol: ': ',
    refrigeratedProduct: 'Refrigerated Product',
    frozenProduct: 'Frozen Product',
    basicSettings: 'Basic Settings',
    productManagement: 'Product Management',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sriday: 'Sunday',
    groupSetting: 'Group Setting',
    members: 'Members',
    man: 'Male',
    female: 'Female',
    managerAddress: 'Manager Address',
    firstRequired: 'The first item is required',
    memberDetail: 'Member Detail',
    memberAddressEdit: 'Member Address Edit',
    memberAddressDetail: 'Member Address Detail',
    memberAddressAdd: 'Member Address Add',
    lastSynchronizationTime: 'Last Synchronization Time: '
  },
  login: {
    rememberMe: 'Remember Me',
    forgetPWD: 'Forgot Password?'
  },
  menu: {
    dataKanban: 'Dashboard',
    commodityManagement: 'Products',
    productList: 'Product List',
    orderManagement: 'Orders',
    orderDeliver: 'Delivery Orders',
    afterSaleOrder: 'After Sales Orders',
    breakDownOrders: 'Break Down Orders',
    userOrder: 'User Orders',
    productEdit: 'Product Edit',
    productCopy: 'Product Copy',
    productView: 'Product View',
    orderDetail: '{name} Detail',
    downloadCenter: 'Download Center',
    promotions: 'Marketing Center',
    campaigns: 'Campaigns',
    addActivity: 'Create Campaign',
    addActivityProduct: 'Create Campaign Product',
    editActivity: 'Edit Campaign',
    editActivityProduct: 'Edit Campaign Product',
    shopCategories: 'Categories',
    buddyManagement: 'Hosts',
    buddyList: 'Host List',
    buddyAdreess: 'Host Addresses',
    buddyAdreessType: 'Addresses Type',
    buddyCommission: 'Host Cashback',
    buddyOCApplication: 'Host Open/Closure Application',
    buddyApplication: 'Host Application',
    buddyWithdrawApplication: 'Cashback Withdrawal',
    rewardsStore: 'Rewards Store',
    rewardsProducts: 'Rewards Products',
    productCategories: 'Product Categories',
    storesManager: 'Shops',
    shopsManager: 'Shop List',
    decoration: 'Layout',
    banner: 'Banner',
    popUps: 'Pop Ups',
    navigation: 'Navigation',
    coupons: 'Vouchers',
    onlineCoupon: 'Online Voucher',
    offlineCoupon: 'Offine Voucher',
    onlineCouponRedeem: 'Online Voucher Redemption',
    attractions: 'Attractions',
    attractionsList: 'Attractions List',
    tickets: 'Tickets List',
    onlineVoucherCode: 'Online Voucher Code Event',
    bundleManager: 'Bundles',
    bundles: 'Bundles List',
    bundleProduct: 'Bundle Products',
    shopSubCategories: 'Sub Categories',
    shopItemLabels: 'Common Labels',
    shopItemStock: 'Modifier List',
    siteLabels: 'Site Labels',
    activityProduct: 'Create Product',
    markettingCenter: 'Marketing Center',
    flexiCombo: 'Flexi Combo',
    suppliersManager: 'Suppliers',
    suppliers: 'Supplier',
    members: 'Members',
    memberList: 'Member List',
    memberAddress: 'Member Address',
    pointsRecord: 'Reward Points',
    memberTier: 'Member Tier',
    notifications: 'Notifications',
    notificationList: 'Notification List',
    offlineCouponRedeem: 'Offline Voucher Redemption',
    voucherPack: 'Voucher Pack',
    luckyDraw: 'Lucky Draw',
    prizeManagement: 'Prize Management',
    participationRecords: 'Participation Records',
    winningRecords: 'Winning Records',
    luckyDrawChance: 'Lucky Draw Chance',
    userGroup: 'User Group',
    customizedPage: 'Customized Page',
    setting: 'Setting',
    clients: 'Clients',
    zooTicketsManager: 'Tickets',
    ticketPackage: 'Ticket Package',
    packageType: 'Single Ticket',
    original_price: 'Original Price',
    listing_price: 'Listing Price',
    ticketOrder: 'Ticket Orders',
    tPackage: 'Bundle Ticket',
    hotSearch: 'Hot Search Keyword',
    countries: 'Countries',
    addTicket: 'Create Ticket',
    multiParkTickets: 'Multi Park Ticket',
    adminUser: 'Admin User',
    referralManager: 'Referral',
    rooms: 'Rooms',
    roomList: 'Room List',
    policyList: 'Policy List',
    hotels: 'Hotels',
    hotelList: 'Hotel List',
    hotelFacility: 'Hotel Facility',
    hotelLabel: 'Hotel Label',
    hotelAddons: 'Hotel Addons',
    restOrder: 'Hotel Order',
    roomsSetting: 'Rooms Setting',
    hotelStatus: 'Hotel Status',
    contentManagement: 'Content Management',
    hotelOrders: 'Room Orders',
    dineinOrders: 'Dine-in Orders',
    couponOrders: 'Counpon Orders',
    couponBundleOrders: 'Counpon Bundle Orders',
    informationManagement: 'Information',
    restaurantList: 'Restaurant List',
    campaignList: 'Campaign List',
    retailList: 'Retail List',
    experienceList: 'Experience List',
    promotionList: 'Promotion List',
    showList: 'Show List'
  },
  form: {
    common: {
      changeHistory: 'Change History',
      operator: 'Operator',
      changeTime: 'Change Time',
      creationTime: 'Creation Time',
      shop: 'Shop',
      status: 'Status',
      rank: 'Display Ranking',
      longitude: 'Longitude',
      latitude: 'Latitude',
      address: 'Address',
      name: 'Name',
      cnName: 'Name (CN)',
      enName: 'Name (EN)',
      shortDescription: 'Short Description',
      shortDescriptionCn: 'Short Description (CN)',
      shortDescriptionEn: 'Short Description (EN)',
      description: 'Description',
      descriptionCn: 'Description (CN)',
      descriptionEn: 'Description (EN)',
      titleCn: 'Title (CN)',
      titleEn: 'Title (EN)',
      contentCn: 'Content (CN)',
      contentEn: 'Content (EN)',
      operationHoursCN: 'Operation Hours (CN)',
      operationHoursEN: 'Operation Hours (EN)',
      language: 'Language',
      file: 'File',
      image: 'Image',
      rating: 'Rating',
      startTime: 'Start Time',
      endTime: 'End Time',
      mail: 'Email Address',
      startDate: 'Start Date',
      endDate: 'End Date',
      ticket: 'Ticket',
      count: 'Count',
      remark: 'Remark',
      video: 'Video',
      link: 'Link',
      userType: 'User Type',
      retail: 'Retail',
      wholesale: 'Wholesale',
      displayNameCn: 'Display Name (CN)',
      displayNameEn: 'Display Name (EN)',
      lontip: 'min -180, max 180',
      lattip: 'min -90, max 90',
      introductionCn: 'Introduction (CN)',
      introductionEn: 'Introduction (EN)'
    },
    rest: {
      city: 'City',
      area: 'Area',
      address: 'Address',
      website: 'Website',
      phoneNumber: 'Phone Number',
      email: 'Email',
      restPolicy: 'Rest Policy',
      restList: 'Hotel List',
      defaultPrice: 'Default Price($)',
      maxSize: 'Maxium Size',
      price: 'price ($)',
      prevWeek: 'Previous Weekend',
      nextWeek: 'Next Weekend',
      showKinds: 'Show Kinds',
      foodRecommendation: 'Food Recommendation',
      travelGuide: 'Travel Guide',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thur',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
      rest: 'Hotel',
      roomAndType: 'Room/Type',
      changeRoomStatus: 'Change Room Status',
      changePrice: 'Change Price',
      available: 'Available',
      maintenance: 'Maintenance',
      closed: 'Closed',
      to_check_in: 'To Check In',
      checked_in: 'Checked In',
      restType: "Hotel Type",
      restRoom: "Hotel Room"
    },
    product: {
      basicMsg: 'Basic Information',
      online: 'Online',
      recommend: 'Recommend',
      searchable: 'Searchable',
      freeDelivery: 'Free Delivery',
      onlineTime: 'Online time',
      shop: 'Shop',
      supplier: 'Supplier',
      shopType: 'Product Type',
      shopCategories: 'Product Category',
      shopSubCategories: 'Sub Category',
      cnName: 'Name (CN)',
      enName: 'Name (EN)',
      maximumLimitPerUser: 'Maximum Purchase Quantity',
      initSale: 'Initial Sold Count',
      shortDescriptionCn: 'Short Description (CN)',
      shortDescriptionEn: 'Short Description (EN)',
      descriptionCn: 'Description (CN)',
      descriptionEn: 'Description (EN)',
      globalLabels: 'Global labels',
      featuredTags: 'Special Labels',
      createFeaturedTags: 'Create the featured label',
      image: 'Image',
      createImage: 'Create the picture',
      imageFiles: 'Picture file',
      buyNotices: 'Policy',
      addNotices: 'Add notes',
      titleCn: 'Title (CN)',
      titleEn: 'Title (EN)',
      contentCn: 'Details (CN)',
      contentEn: 'Details (EN)',
      shopStock: 'Modifier',
      createStock: 'Create the product Stock',
      priceUnit: 'price ($)',
      costPriceUnit: 'Cost price (yuan)',
      linePriceUnit: 'Underlined price (yuan)',
      slaingStock: 'Total Stock',
      warningStock: 'Warning Stock',
      lockingStock: 'Inventory quantity',
      preferences: 'Preferences',
      createPreferences: 'Create the Preferences',
      title: 'Title',
      displayOrder: 'Display Order',
      minCount: 'Min. Option Selected',
      maxCount: 'Max. Option Selected',
      preferenceOptions: 'Preference Options',
      createPreferOptions: 'Create thr Preference Options',
      name: 'name',
      price: 'price',
      sku: 'SKU ID',
      sellingPrice: 'Selling Price',
      costPrice: 'Cost Price',
      linePrice: 'Strike Through Price',
      headCommission: 'Group-buy Sales Commission (%)(Self-collection)',
      saleableInventory: 'Saleable Inventory',
      warningInventory: 'Warning Inventory',
      lockInventory: 'Lock Inventory',
      compulsoryItem: 'Compulsory Item',
      categories: 'Categories',
      subCategories: 'Sub Categories',
      selectASubcategory: 'Please select a subcategory',
      selectACategory: 'Please select a category',
      exclusiveGoods: 'Exclusive Goods',
      regularSale: 'Open For Sale',
      specialProperties: 'Special Properties',
      invisibleLabel: 'Invisible Labels',
      recommendedSize: 'Recommended Size',
      offlineCoupons: 'Offline Coupons',
      // commodityInventory: 'Commodity Inventory',
      sevenDaysReturn: 'Support After Sales',
      zone: 'Zone',
      section: 'Section',
      bin: 'Bin',
      commodityInventory: 'Modifier',
      rankingPriority: 'Display Ranking',
      parentCategory: 'Parent Categories',
      sitePackInfo: 'Pack Info',
      stockTaking: 'Stock Taking',
      ticket: 'Ticket',
      show: 'Show',
      hotelRoom: 'Hotel Room',
      offlineCoupon: 'Offline Coupon',
      ticketPackage: 'Ticket Package',
      voucherPack: 'Voucher Pack',
      afterSalesWindow: 'After Sales Window',
      ranking: 'Display Ranking',
      invisibleFor: 'Invisible For',
      supportWholesale: 'Support Wholesale',
      wholesalePrice: 'Wholesale Price',
      directDeliveryHostCommission: 'Group-buy Sales Commission (%)(Delivery)',
      retail: 'Retail',
      wholesale: 'Wholesale',
      retailAndwholesale: 'Retail and Wholesale',
      allowMultiple: 'Same Option Multiple Times',
      nonHalal: 'Non Halal',
      allowMultiple: 'Allow multiple same option',
      productDetail: 'Product Detail',
      productDisplay: 'Product Display',
      label: 'Label'
    },
    order: {
      userOrderDetails: 'User order details',
      userDeliverDetails: 'Delivery order details',
      orderNumber: 'order number',
      totalAmount: 'total amount',
      discountInformation: 'discount information',
      paymentMethod: 'payment method',
      dsTime: 'Delivery/self-pickup time',
      customerMsg: 'Customer Information',
      order: 'Order',
      orderInfo: 'Order Information',
      custInfo: 'Customer Information',
      deliverInfo: 'Delivery Information',
      amountInfo: 'Amount Information',
      items: 'Items',
      receiverInfo: 'Receiver Information',
      hostInfo: 'Host Information',
      collectionInfo: 'Collection Information',
      couponInfo: 'Coupon Information',
      logisticInfo: 'Logistic Information',
      productName: 'Product Name',
      sku: 'SKU',
      removeProducNotice: 'Warning: after removing the goods, the corresponding refund needs to be processed manually',
      currentCount: 'Current',
      romveCount: 'Remove',
      romoveProduct: 'Remove Product',
      productCountLessThanOne: 'Commodity quantity is less than 1',
      ticketName: 'Ticket Order Number',
      salesAmount: 'Sales Amount',
      costAmount: 'Cost Amount',
      ticketing: 'Ticketed Tickets',
      contactInfo: 'Contact Information',
      ticketDate: 'Visit Date',
      ticketTime: 'Visit Time',
      totalTax: 'GST({gst}%)',
      totalPriceExcludingTax: 'Subtotal',
      totalTaxAmount: 'Paid Amount',
      date: 'Date',
      price: 'Price',
      roomCount: 'Room Count',
      roomPolicyName: 'Room Policy Name'
    },
    activity: {
      basicActivitySettings: 'Basic Campaign Settings',
      activityProductManagement: 'Campaign Product Management',
      isOnline: 'Active',
      online: 'Active',
      offline: 'Inactive',
      homePageDisplay: 'Home Page Display',
      activityName: 'Campaign Name (CN)',
      activityNameEN: 'Campaign Name (EN)',
      activityType: 'Campaign Type',
      activityTime: 'Campaign Time',
      startTime: 'Start Time',
      endTime: 'End Time',
      activityCover: 'Cover Image',
      deliveryTime: 'Delivery Time',
      flashSaleSessions: 'Flash Sale Sessions',
      sessions: 'Session ',
      remove: 'Remove',
      addFlashSaleSessions: 'Add Session',
      nextStep: 'Next Step',
      productName: 'Product Name',
      cover: 'Cover',
      shop: 'Shop',
      supplier: 'Supplier',
      participatingModel: 'Participating Modifiers',
      model: 'Modifier',
      price: 'Price',
      addActivityProduct: 'Create Campaign Product',
      activityProduct: 'Campaign Product',
      freePostage: 'Free Shipping',
      purchaseLimitOfTheSameUser: 'Purchase Limit Of The Same User',
      minimumGroupSize: 'Minimum Group Size',
      timeWindow: 'Time Window(Minute)',
      startEndTime: 'Start And End Time',
      submitShopActivitiesItemsWarning1: 'Select at least one modifier to participate in the Campaign',
      submitShopActivitiesItemsWarning2: 'Please fill in the price of participating modifiers',
      chooseProduct: 'Choose Product',
      submitProductListDialogWarning1: 'Please select an item',
      sameUserPurchaseLimit: 'Same User Purchase Limit',
      slashSaleCount: 'Slash Count',
      logisticInfo: 'Logistic Information',
      sku: 'SKU',
      removeProducNotice: 'Warning: after removing the goods, the corresponding refund needs to be processed manually',
      currentCount: 'Current',
      romveCount: 'Remove',
      romoveProduct: 'Remove Product',
      productCountLessThanOne: 'Commodity quantity is less than 1'
    },
    buddy: {
      addressType: 'Address Type',
      houseNumber: 'House Number',
      officeBuilding: 'Office Building',
      zipCode: 'Zip Code',
      deliveryPrompt: 'Delivery Prompt',
      paymentInformation: 'Payment Information',
      buddyType: 'Host Type',
      buddyCode: 'Host Code',
      buddyPSW: 'Host Password',
      buddyName: 'Host Name',
      mobile: 'Mobile',
      whatsAppNum: 'Whatsapp Number',
      wechatNum: 'Wechat Num',
      icFrontPhoto: 'IC Front Photo',
      icBackPhoto: 'IC Back Photo',
      record: 'Record',
      buddyMobile: 'Host Mobile',
      royaltyAmount: 'Royalty Amount',
      remarks: 'Remarks',
      supportRefrigeratedProducts: 'Support Refrigerated Products',
      supportFrozenProducts: 'Support Frozen Products',
      supportHalalProducts: 'Support Halal Products',
      halalProductsOnly: 'Halal Products Only',
      zeroCommission: 'Zero Cashback',
      whatsappGroupLink: 'Whatsapp Group Link',
      bankName: 'Bank Name',
      bankSwiftCode: 'Bank Swift Code',
      accountName: 'Account Name',
      accountNumber: 'Account Number',
      paynowNumber: 'Paynow Number',
      roadName: 'Road Name',
      blockNumber: 'Block Number'
    },
    rewardsStore: {
      rewardsProductType: 'Type',
      sellingPoints: 'Redemption Points'
    },
    store: {
      paymentInformation: 'Payment Information',
      settlementInfo: 'Settlement Information',
      chargeGSTSeparately: 'Charge GST Separately',
      official: 'Official',
      automatedOrderProcessing: 'Automated Order Confirmation',
      smsNotification: 'SMS Notification',
      smsNotificationNumber: 'SMS Notification Number',
      itemTypes: 'Supported Item Types',
      adminUser: 'Admin User',
      logo: 'Logo',
      operationTimeStart: 'Operation Time Start',
      operationTimeEnd: 'Operation Time End',
      phoneNumber: 'Phone Number',
      email: 'Email',
      whatsAppNumber: 'WhatsApp Number',
      paynowWechat: 'Wechat ID',
      shopRuleszh: 'Shop Notice (CN)',
      shopRulesen: 'Shop Notice (EN)',
      shopCategories: 'Shop Categories',
      displayInformation: 'Display Information',
      hideSoldCount: 'Hide Sold Count',
      shopDisplayType: 'Shop Display Type',
      productSortingRule: 'Product Sorting Rule',
      tabChoice: 'Tab Choice',
      websiteInformation: 'Website Information',
      shopSubDomain: 'Shop Sub Domain',
      themeMode: 'Theme Mode',
      themeColor: 'Theme Color',
      deliveryInformation: 'General Delivery Settings',
      deliveryZoneSettings: 'Delivery Zone Settings',
      supportDelivery: 'Support General Delivery',
      minDeliveryAmount: 'Min. Delivery Amount',
      freeDeliveryAmount: 'Free Delivery Amount',
      deliveryDateDisplayMode: 'Delivery Date Display Mode',
      numberOfDays: 'Number of Days',
      cutoffDate: 'Cutoff Date',
      shopNoDeliveryDate: 'No Delivery Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      deliveryTimeSlot: 'General Delivery Time Slot',
      deliveryTipszh: 'Delivery Tips (CN)',
      deliveryTipsen: 'Delivery Tips (EN)',
      deliveryFeeCalculationMethod: 'Delivery Fee Calculation Method',
      deliveryFeebyDistance: 'Delivery Fee by Distance',
      deliveryFeebyOrderAmount: 'Delivery Fee by Order Amount',
      thirdPartyDelivery: 'Third Party Delivery',
      partnersEmail: 'Partner’s Email',
      instructionforDriver: 'Instruction for Driver',
      foodacornExpress: 'Foodacorn Express',
      grabExpress: 'Grab Express',
      keepDeliveryFee: 'Keep Delivery Fee',
      selfPickupInformation: 'Self Pickup Information',
      supportSelfPickup: 'Support Self Pickup',
      offsetMin: 'Offset (min)',
      pickUpAdvanceTimeMin: 'Pick Up Advance Time(min)',
      pickUpDateDisplayRange: 'Pickup Date Display Mode',
      mondaySelfPickupSlots: 'Monday Self-Pickup Slots',
      tuesdaySelfPickupSlots: 'Tuesday Self-Pickup Slots',
      wednesdaySelfPickupSlots: 'Wednesday Self-Pickup Slots',
      thursdaySelfPickupSlots: 'Thursday Self-Pickup Slots',
      fridaySelfPickupSlots: 'Friday Self-Pickup Slots',
      saturdaySelfPickupSlots: 'Saturday Self-Pickup Slots',
      sundaySelfPickupSlots: 'Sunday Self-Pickup Slots',
      selfPickupTipszh: 'Self Pickup Tips (CN)',
      selfPickupRipsen: 'Self Pickup Tips (EN)',
      payNowType: 'PayNow Type',
      creditCartPayment: 'Credit Card Payment',
      cashOnDelivery: 'Cash on Delivery',
      merchantGuarantee: 'Merchant Guarantee',
      settlementInformation: 'Settlement Information',
      wechatFeeRate: 'Wechat Fee Rate(%)',
      automaticPaynowFeeRate: 'Automatic Paynow Fee Rate(%)',
      generalCommissionRate: 'General Cashback Rate(%)',
      platformCommissionRate: 'Platform Cashback Rate(%)',
      creditCardsCommissionRate: 'Credit Cards Cashback Rate(%)',
      selfOperation: 'Self Operation',
      generalInformation: 'General Information',
      cutOffMin: 'Cut-off (min)',
      quota: 'Quota',
      warningQuota: 'Warning Quota',
      deliveryWeekdays: 'Delivery Days',
      surcharge: 'Surcharge',
      startDistanceInclusive: 'Start Distance (km)(inclusive)',
      endDistanceExclusive: 'End Distance (km)(exclusive)',
      startAmountInclusive: 'Start Amount (inclusive)',
      endAmountExclusive: 'End Amount（exclusive）',
      expressFee: 'Delivery Fee',
      payNowQRCode: 'PayNow QR Code',
      payNowUEN: 'PayNow UEN',
      payNowPhoneNumber: 'PayNow Phone Number',
      start: 'Start',
      end: 'End',
      pickupCutoff: 'Pick Up Cutoff Date',
      offlineShop: 'Offline Stores',
      offlineShopDetails: 'Offline Store Details',
      operationHours: 'Operation Hours',
      longitude: 'Longitude',
      latitude: 'Latitude',
      address: 'Address',
      entityInformation: 'Entity Information',
      offlineEntityType: 'Offline Entity Type',
      offlineEntity: 'Offline Entity',
      shippingPoint: 'Shipping Point',
      supportPickup: 'Support Pickup',
      SMSRecord: 'SMS Record',
      categories: 'Categories',
      stripeAccount: 'Stripe Account',
      serviceFee: 'Service Fee (%)',
      deliveryZones: 'Delivery Zones',
      createDeliveryZone: 'Create Delivery Zone',
      online: 'Online',
      zoneNamecn: 'Zone Name (CN)',
      zoneNameen: 'Zone Name (EN)',
      postalCodeRange: 'Postal Code Range',
      supportZoneDelivery: 'Support Delivery Zones',
      zoneDeliveryTimeSlot: 'Time Slot for Delivery Zones',
      suspendDeliveryTimeSlot: 'No Delivery Date',
      suspendDeliveryStartDate: 'Start Date',
      suspendDeliveryEndDate: 'End Date',
      validCommas: 'Please separate with English commas',
      zoneName: 'Zone Name'
    },
    decoration: {
      banner: 'Name',
      popups: 'Pop Ups Name',
      navigation: 'Navigation Name',
      beginDate: 'Begin Date',
      endDate: 'End Date',
      platform: 'Channel',
      promotionGroup: 'Position',
      rankingPriority: 'Display Ranking',
      mainTitle: 'Main Title',
      subtitle: 'Subtitle',
      targetPage: 'Landing Page',
      customLink: 'Custom Link',
      tips: 'Remark',
      navigationType: 'Navigation Type',
      navigationButton: 'Navigation Button',
      subtitlecn: 'Subtitle (CN)',
      subtitleen: 'Subtitle (EN)',
      CustomUrl: 'Custom Url',
      image: 'Image',
      ranking: 'Display Ranking',
      naviNameCN: 'Name (CN)',
      naviNameEN: 'Name (EN)',
      thirdPartyService: 'Third Party Service',
      POSvendor: 'POS Vendor',
      deliveryVendor: 'Delivery Vendor',
      merchantId: 'Merchant Id',
      secretKey: 'Secret Key',
      clientId: 'Client Id',
      pageTitle: 'Page Title',
      customizedPageElement: 'Customized Page Element',
      pageName: 'Page Name'
    },
    coupon: {
      online: 'Online',
      objectType: 'Target Type',
      client: 'Client',
      shop: 'Shop',
      applicationObject: 'Targets',
      name: 'Name',
      receiveType: 'Collection Method',
      deliveryType: 'Shipping Method',
      type: 'Type',
      discountAmount: 'Coupon Value($)',
      useDoor: 'Min. Spend($)',
      getTimes: 'Max. Quantity Per User',
      stock: 'Stock',
      validityType: 'Validation Type',
      startDate: 'Start Date (Optional)',
      endDate: 'End Date',
      simpleDsc: 'Short Description',
      describe: 'Description',
      instructions: 'Terms & Conditions',
      img: 'Image',
      validDays: 'Valid Days',
      onlineCouonDetail: 'Online Coupon Details',
      all: 'All',
      offlineCouponDetail: 'Offline Coupon Details',
      eventName: 'Event Name',
      codeStartDate: 'Start Date',
      codeType: 'Code Type',
      maximum: 'Maximum Use Count*',
      redemptionLimit: 'Same User Redemption Limit',
      voucherType: 'Voucher Type',
      voucherValue: 'Coupon Value($)',
      appliedTargetType: 'Applied Target Type*',
      chooseClient: 'Choose Client',
      chooseShop: 'Choose Shop',
      miniSpend: 'Min. Spend',
      codeGeneration: 'Code Generation',
      voucherCode: 'Voucher Code',
      onlyNew: 'New User Only',
      chooseProduct: 'Choose Product',
      chooseOfflineCoupon: 'Choose Offline Coupon',
      numberOfCodes: 'Number of Codes',
      authShop: 'Authorized Shop',
      price: 'Price',
      couponDiscout: 'Coupon Discount(%)',
      originalPrice: 'Original Price',
      maxDiscount: 'Max Discount($)',
      createProduct: 'Create Product',
      selectProduct: 'Select Product',
      dineIn: 'Eligible for Dine in',
      applyTo: 'Apply to',
      entireShop: 'Entire Shop',
      selectedProducts: 'Selected Products',
      couponName: 'Coupon Name',
      voucherPackName: 'Voucher Pack Name',
      useGuideCN: 'Use Guide (CN)',
      useGuideEN: 'Use Guide (EN)',
      PolicyCN: 'Policy (CN)',
      PolicyEN: 'Policy (EN)',
      bundleValue: 'Bundle Value',
      expirationInDays: 'Expiration(in days)',
      bundledCoupon: 'Bundled Coupon',
      quantity: 'Quantity',
      coupon: 'Coupon',
      chooseSite: 'Choose Site',
      chooseTicket: 'Choose Ticket Type',
      entireTicket: 'Entire Ticket',
      selectedTicket: 'Choose Ticket Type',
      ticketApplyTo: 'Apply to',
      thirdPartyVoucherId: 'Third Party Voucher ID',
      basePrice: 'Base Price',
      basePriceText: 'This is only applied to ticketing orders'
    },
    attractions: {
      redemptionCode: 'Redemption Code',
      site: 'Attraction',
      siteALevel: 'Site A Level',
      city: 'City',
      website: 'Website',
      contactNumber: 'Contact Number',
      recommendedVisitDuration: 'Recommended Visit Duration',
      ticketGuideCN: 'Ticket guide (CN)',
      ticketGuideEN: 'Ticket guide (EN)',
      transportationGuideCN: 'Transportation Guide (CN)',
      transportationGuideEN: 'Transportation Guide (EN)',
      remindersCN: 'Reminders (CN)',
      remindersEN: 'Reminders (EN)',
      ticketType: 'Ticket Type',
      siteCategories: 'Site Categories',
      bestPrice: 'Best Price',
      specialLabel: 'Special Label',
      audioFiles: 'Audio Files',
      imageFiles: 'Image Files',
      subsite: 'Subsite',
      groupName: 'Group Name',
      packInfoId: 'Pack Info',
      applicableSpectator: 'Eligibility',
      bookingTimeRule: 'Booking Time Rule',
      validity: 'Validity',
      serviceIncluded: 'Inclusive of',
      useGuide: 'How to Use',
      paperTicketRedemptionAddress: 'Paper Ticket Redemption Address',
      refundPolicy: 'Refund Policy',
      purchasePolicy: 'Terms & Conditions',
      onlyForToday: 'Only For Today',
      ticketPack: 'Ticket Pack',
      globaltixProduct: 'Globaltix Product',
      price: 'Selling Price',
      cost: 'Cost',
      basicPrice: 'Listing Price',
      refundable: 'Refundable',
      theLatestBookingTime: 'The Latest Booking Time Of The Day',
      validDaysOfWeek: 'Valid Days Of Week',
      label: 'Label',
      siteName: 'Site Name',
      siteVideo: 'Site Video',
      fileAttachment: 'File Attachment',
      mapMode: 'Map Mode',
      delDate: 'Deleted at',
      ticketsI: 'Ticket Package',
      ticketCategoriesDetails: 'Ticket Type Information',
      ticketCategoriesList: 'Ticket Type',
      ticketDetails: 'Calendar Ticket',
      ticketingSystem: 'Ticketing System',
      ticketingSystemProduct: 'Ticketing System Product',
      ticketName: 'Ticket Name',
      tips: 'Remark'
    },
    bundle: {
      name: 'Bundle Name (CN)',
      name_en: 'Bundle Name (EN)',
      start_date: 'Start Date',
      end_date: 'End Date',
      shortDescriptionCn: 'Short Description (CN)',
      shortDescriptionEn: 'Short Description (EN)',
      image: 'Image',
      addImage: 'Add new image',
      addBundle: 'Add a Bundle item',
      itemType: 'Item type',
      itemProduct: 'Product',
      itemDiscount: 'Discount Percent',
      product: 'Products',
      item: 'Shop item bundle',
      delDate: 'Deleted at',
      main: 'Must Buy Products',
      optional: 'Optional Products',
      detail: 'Bundle item',
      coverImage: 'Cover Image',
      discountType: 'Discount Type',
      discountPercent: 'Percentage Discount Off',
      discountPercent2: 'Discount Percentage Off',
      discountValue: 'Money Value Off',
      discountValue2: 'Money Value Off',
      discountValue3: 'Money Value Off'
    },
    flexiCombo: {
      period: 'Period',
      details: 'Details',
      name: 'Promotion Name',
      entrieShop: 'Entire Shop',
      stackable_discount: 'Discount Stackable',
      termsAndConditions: 'Terms & Conditions',
      shopId: 'Shop',
      requierQuantity: 'If Item Quantity reaches',
      discountAmount: 'Discount would be',
      discount_type: 'Discount Type',
      discountDetails: 'Discount Details',
      startDate: 'Start time',
      endDate: 'End time',
      deal_criteria: 'Deal Criteria',
      requierValue: 'If Order Value reaches',
      total_number: 'Total Number of Flexi Combo Orders',
      discountApply: 'Discount Apply to Entire Shop',
      stackableDiscount: 'Stackable Discount',
      selectProduct: 'Please select products after submission',
      stackable: 'Ex. Buy 2SGD Get 1 Sample, Buy 4SGD Get 2 Samples, Buy 6SGD Get 3 Samples, etc. 20 samples at most per order',
      percent_off: 'Percentage Discount Off',
      money_value_off: 'Money Value Off',
      item_quantity: 'Item Quantity Reaches X',
      item_value: 'Order Value Reaches X'
    },
    supplers: {
      name: 'Name',
      email: 'Email',
      wechat: 'Wechat ID',
      whatsapp: 'WhatsApp ID',
      phoneNumber: 'Phone Number',
      img: 'Icon'
    },
    members: {
      memberName: 'Member Name',
      addressName: 'Address Name',
      manualTopUp: 'Manual Top Up',
      manualDeduct: 'Manual Deduct',
      batchUpdatePoints: 'Batch Update Points',
      rewardName: 'Reward Name',
      tiresName: 'Tire Name',
      benefits: 'Benefits',
      memberCardImage: 'Member Card Image',
      tierName: 'Tier Name',
      tierNameEN: 'Tier Name (EN)',
      minimumPoints: 'Minimum Points',
      icon: 'Icon',
      name: 'Name',
      nameEN: 'Name (EN)',
      brief: 'Brief',
      briefEN: 'Brief (EN)',
      detail: 'Details (CN)',
      detailEN: 'Details (EN)',
      pointOperationType: 'Point Operation Type',
      userId: 'User Id',
      remarks: 'Remarks',
      topUpBy: 'Top Up By',
      topUpAmount: 'Top Up Amount',
      amountPaid: 'Amount Paid',
      deductBy: 'Deduct By',
      deductAmount: 'Deduct Amount',
      allIn: 'Either User ID or Phone Number must be filled in',
      phoneNumber: 'Phone Number',
      pointOperation: 'Point Operation',
      addressee: 'Name',
      address: 'Address Name',
      postalCode: 'Postal Code',
      unitNo: 'Unit No.',
      buildingName: 'Building Name',
      defaultAddress: 'Default Address',
      addressType: 'Address Type',
      residentialType: 'Residential Type',
      roadName: 'Road Name',
      private: 'Private',
      business: 'Business',
      user: 'User/Phone Number',
      blockNumber: 'Block Number',
      nickname: 'Nickname',
      userName: 'Name',
      avatar: 'Avatar',
      gender: 'Gender',
      birthday: 'Birthday',
      currBuddy: 'Current Buddy',
      offlineShopMember: 'Offline Shop Member',
      userType: 'User Type',
      addAddress: 'Add Address',
      email: 'Email',
      userGroup: 'User Group',
      dateRange: 'Date Range'
    },
    notifications: {
      pushType: 'Push Type',
      scheduledTime: 'Scheduled Time',
      audience: 'Audience',
      title: 'Title',
      subTitle: 'Sub Title',
      content: 'Content',
      buttonText: 'Button Text',
      buttonLink: 'Button Link'
    },
    luckyDraw: {
      rankingPriority: 'Display Ranking',
      prizeableType: 'Prizeable Type',
      prizeableId: 'Prizeable Id',
      probability: 'Probability',
      countRule: '0 or blank represents unlimited quantity',
      probabilityRule: 'Winning percentage, range 0-100',
      id: 'Id',
      reedCode: 'Redeem Code'
    },
    groupUser: {
      name: 'Name',
      groupType: 'Group Type',
      priceTier: 'Price Tier',
      retail: 'Retail',
      wholesale: 'Wholesale',
      userName: 'Name',
      avatar: 'Avatar',
      nickName: 'Nickname',
      gender: 'Gender',
      addMembers: 'Add Member',
      selectMembers: 'Select Member'
    },
    setting: {
      customerServiceName: 'Customer Service Name',
      customerServiceLogo: 'Customer Service Logo',
      customerServicePhoneNumber: 'Customer Service Phone Number',
      defaultImage: 'Default Image',
      noOfNavigationRows: 'No. Of Navigation Rows',
      customerServiceWhatsApp: 'Customer Service WhatsApp',
      customerServiceEmail: 'Customer Service Email',
      emailsTips: 'Please use semicolon (;) to separate different emails',
      emailFromName: 'Email From Name',
      officialAccountLink: 'Official Account Link',
      officialAccountName: 'Official Account Name',
      officialAccountIcon: 'Official Account Icon',
      slashSaleWechatMomentImage: 'Slash Sale Wechat Moment Image',
      groupBuyWechatMomentImage: 'Group Buy Wechat Moment Image',
      noticeCN: 'Notice (CN)',
      NoticeEN: 'Notice (EN)',
      emailAddress: 'Email Address',
      googleAnalyticsTag: 'Google Analytics Tag',
      clientType: 'Client Type',
      moduleArray: 'Module Array',
      turnOnWeChatEvent: 'Turn On WeChat Event',
      turnOnCouponDistribution: 'Turn On Coupon Distribution',
      invoiceSetting: 'Invoice Setting',
      invoiceLanguage: 'Invoice Language',
      languageCn: 'Chinese',
      languageEn: 'English',
      companyName: 'Company Name',
      companyRegNo: 'Company Reg. No.',
      GSTRegNo: 'GST Reg. No.',
      companyAddress: 'Company Address',
      companyPostalCode: 'Company Postal Code',
      invoicePrefix: 'Invoice Prefix',
      paymentSettings: 'Payment Settings',
      pendingPaymentWindow: 'Pending Payment Window (min)',
      // merchantId: 'Merchant Id',
      // paymentKey: 'Payment Key',
      // APICertificate: 'API Certificate',
      // APIKey: 'API Key',
      // kserMerchantId: 'Kser Merchant Id',
      // kserPrivateKey: 'Kser Private Key',
      // FomopayKey: 'Fomopay Key',
      // fomopayService: 'Fomopay Service',
      // fomopayMid: 'Fomopay Mid',
      // fomopayTid: 'Fomopay Tid',
      // apMid: 'Ap Mid',
      // apMerchantCode: 'Ap Merchant Code',
      // apPaynowMid: 'Ap Paynow Mid',
      // apPaynowTid: 'Ap Paynow Tid',
      // stripeApikey: 'Stripe Api Key',
      // attractPayMerchantId: 'AttractPay Merchant Id',
      // attractPayPrivateKey: 'AttractPay Private Key',
      // uniwebWechatOutlet: 'Uniweb Wechat Outlet',
      // uniwebWechatPartner: 'Uniweb Wechat Partner',
      // eghlAlipayMerchantId: 'Eghl Alipay Merchant Id',
      // eghlAlipayMerchantPassword: 'Eghl Alipay Merchant Password',
      // eghlFpxMerchantId: 'Eghl Fpx Merchant Id',
      // eghlFpxMerchantPassword: 'Eghl Fpx Merchant Password',
      inventorySettings: 'Inventory Settings',
      inventoryProvider: 'Inventory Provider',
      buddySettings: 'Host Settings',
      minimumHostWithdrawAmount: 'Minimum Host Withdraw Amount',
      loyaltySettings: 'Loyalty Settings',
      accumulationRadio: 'Accumulation Radio',
      accTips: 'Refers to how many points will be accumulated with every dollar expense',
      redemptionRadio: 'Redemption Radio',
      redTips: 'Refers to how many points will be needed for every dollar redemption',
      minimumPointsPerOrder: 'Minimum Points Per Order',
      rules: 'Rules',
      termsInformation: 'Terms Information',
      termsAndConditions: 'Terms And Conditions',
      privacyPolicy: 'Privacy Policy',
      gameRule: 'Game Rule',
      phone_number_code: 'Phone Number Code',
      ranking: 'Display Ranking',
      countryCode: 'Country Code',
      role: 'Role',
      locale: 'Default Langugae',
      password: 'Password',
      cmpassword: 'Confirm Password',
      permissionInfo: 'Permission Information',
      permissionType: 'Permission Type',
      permissionList: 'Permission List'
    },
    zoo: {
      ticket_type: 'Ticket Type',
      active: 'Active',
      site: 'Attraction',
      site2: 'Attractions',
      ticket_package: 'Ticket Package',
      ticket_package_id: 'Ticket Package',
      name: 'Name (CN)',
      name2: 'Name (EN)',
      inclusions: 'Inclusions',
      peak_season: 'Peak Season',
      visit_date_required: 'Visit Date Required',
      advance_booking_in_minutes: 'Advance Booking(in mins)',
      use_guide: 'Use Guide',
      max_pax: 'Max per Order',
      min_pax: 'Min per Order',
      capacity_control: 'Capacity Control',
      description: 'Description',
      short_description: 'Short Description',
      terms_and_conditions: 'Terms and Conditions',
      validity_in_days: 'Validity(in days)',
      local_residence_only: 'Local Residence Only',
      cancellation_policies: 'Cancellation Policies',
      cancellable: 'Cancellale',
      paper_ticket_redemption_required: 'Exchange for Paper Tickets',
      ranking: 'Display Ranking',
      age_label: 'Age Label',
      last_synced_at: 'Last Synced at',
      third_party_platform: 'Third Party Platform',
      third_party_pid: 'Third Party Platform Product ID',
      parent_ticket_type: 'Parent Single Ticket',
      original_price: 'Original Price',
      listing_price: 'Listing Price',
      globaltix_reseller_id: 'Globaltix Reseller ID',
      globaltix_ticket_id: 'Globaltix Ticket ID',
      booking_time_desc: 'Booking Time ',
      validity_desc: 'Validity',
      visitor_applicability: 'Visitor Applicability',
      inclusion_desc: 'Included Items',
      redemption_guide: 'Redemption Guide',
      redemption_address: 'Redemption Address',
      cancellation_guide: 'Cancellation Policies',
      additional_guide: 'Extra Information',
      t_description: 'Description,',
      settlement_price: 'Settlement Price',
      payable_amount: 'Payable Amount',
      t_original_price: 'Original Price',
      thirdPartyInformation: 'Third Party Resource',
      guideAndPolicies: 'Guide And Policies',
      t_end_date: 'Third Party End Date',
      t_start_date: 'Third Party Start Date',
      validDaysOfWeek: 'Days of Weeks',
      ticket_type2: 'Package Type',
      tPackage: 'Package Item',
      packageName: 'Package Name',
      location: 'Region',
      policyInformation: ' Policy Information',
      basicTicketSettings: 'Basic Information',
      manageTickets: 'Manage Tickets',
      payableAmount: 'Payable Amount',
      exceptionDates: 'Exception Dates',
      exceptionalDateRanges: 'Exceptional Date Ranges',
      exceptional_type: 'Exceptional Type',
      allTickets: 'All Tickets',
      pax: 'Min. Selection',
      site_ids: 'Sites',
      sharedCalendar: 'Shared Calendar',
      sync: 'Sync PLUs',
      support_timeslot: 'Support Timeslot',
      admission_time: 'Admission Time'
    },
    hotSearch: {
      name: 'Keyword',
      name_en: 'Keyword（EN）',
      client: 'Client'
    },
    hotel: {
      openedAt: 'Opened At',
      bookingType: 'Booking Type',
      commercialVicinity: 'Commercial Vicinity',
      starRating: 'Star Rating',
      paymentDesc: 'Payment Description',
      bookingNotice: 'Booking Notice',
      foreignerPolicies: 'Foreigner Policies',
      globalLabels: 'Global labels',
      roomName: 'Room Name',
      hotelName: 'Hotel',
      hotelRoom: 'hotel Room',
      policyName: 'Policy Name',
      price: 'Price',
      unit: 'Unit：$',
      priceCalendar: 'Price Calendar',
      price2: 'price',
      costPrice: 'cost price',
      empty: 'Empty',
      Managepolicies: 'Manage Policies',
      stayDates: 'Stay Dates',
      hotel: 'Hotel'
    },
    referral: {
      target: 'Link',
      inviteRewardType: 'Referral Invited Reward Type',
      inviteReward: 'Referral Invited Reward',
      userRewardType: 'Referral User Reward Type',
      userReward: 'Referral User Reward',
      validDate: 'Valid Date',
      coupon: 'Coupon'
    },
    basicMessage: {
      supportChope: 'Support Chope',
      multipleChopeId: 'Multiple Chope Id',
      chopeRestaurantID: 'Chope Restaurant ID',
      chopeRestaurantName: 'Chope Restaurant Name',
      chopeID: 'Chope ID',
      averagePrice: 'Average Price',
      homepageDisplay: 'Homepage Display',
      deliveryStartDate: 'Delivery Start Date',
      deliveryEndDate: 'Delivery End Date',
      dishes: 'Restaurant Item',
      chopeMessage: 'Chope Message',
      poiType: 'POI Type',
      poi: 'POI',
      PromotionTimeCn: 'Promotion Time(CN)',
      PromotionTimeEn: 'Promotion Time(EN)',
      usage: 'Redemption Guide(CN)',
      usage_en: 'Redemption Guide(EN)',
      ticketGuide: 'Ticket Guide(CN)',
      ticketGuide_en: 'Ticket Guide(EN)',
      TicketType: 'Ticket Type',
      ShowTime: 'Show Time(CN)',
      ShowTime_en: 'Show Time(EN)',
      RecommendedDuration: 'Recommended Duration(In Minutes)',
      scenicSpot: 'Scenic Spots'
    }
  },
  table: {
    common: {
      operate: 'Actions',
      shop: 'Shop',
      status: 'Status'
    },
    product: {
      shop: 'Shop',
      supplier: 'Supplier',
      name: 'Name',
      basicInformation: 'Basic Information',
      productModel: 'Product model',
      status: 'Status',
      pickupMethod: 'Pick method',
      operate: 'Operate',
      form: {
        basicMsg: 'Basic Information',
        online: 'Online',
        recommend: 'Recommend',
        searchable: 'Searchable',
        freeDelivery: 'Free Delivery',
        onlineTime: 'Online time',
        shop: 'Shop',
        supplier: 'Supplier',
        shopType: 'Product Type',
        shopCategories: 'Product categories',
        shopSubCategories: 'Product subcategories',
        cnName: 'CNname',
        enName: 'ENname',
        maximumLimitPerUser: 'Purchase limit for the same user',
        initSale: 'Init Sale',
        shortDescriptionCn: 'Short Description (CN)',
        shortDescriptionEn: 'Short Description (EN)',
        descriptionCn: 'Description (CN)',
        descriptionEn: 'Description (EN)',
        globalLabels: 'Default Label'
      }
    },
    order: {
      username: 'Username',
      host: 'Host',
      productDetail: 'Product Detail',
      shippingDetail: 'Shipping Detail',
      ordersDetail: 'Orders Detail',
      deliveryDetail: 'Delivery Detail',
      orders: 'Orders',
      meetMoq: 'Meet Moq',
      bookingInfo: 'Booking Info',
      roomInfo: 'Room Info',
      addons: 'Addons',
      payTime: 'Payment Time',
      adminRemark: 'Admin Remarks',
      roomsCount: 'Rooms Count'
    },
    activity: {
      addActivity: 'Create Campaign',
      confirmDeleteActivity: 'Are you sure to delete this campaign?',
      participate: 'Participate',
      modifier: 'Modifier',
      sellingPrice: 'Selling Price($)',
      campaignPrice: 'Campaign Price($)',
      lowestPrice: 'Lowest Price($)',
      groupBuyPrice: 'Group Buy Price($)',
      activityProductSellingPriceAndCampaignPrice: 'Selling Price: S$ {sellingPrice}, Campaign Price: S$ {campaignPrice}',
      activityProductSellingPrice: 'Selling Price: S$ {sellingPrice}'
    }
  },
  dashboard: {
    todayMission: 'Overview',
    userOrders: 'User Orders',
    unconfirm: 'To Confirm',
    unconfirm2: 'Pending Confirmation',
    undelivery: 'To Ship Today',
    cancling: 'Cancelling',
    afterbuy: 'After Sales Orders',
    grouporders: 'Groupbuy Delivery Orders',
    unMOQ: 'MOQ Not Met',
    totalInfo: 'Sales',
    open: ' Sales',
    more: 'More',
    realSell: 'Revenue',
    income: 'Merchandise',
    chongzhi: 'Recharge amount (yuan)',
    totalOrders: 'Total orders',
    mallOrders: 'Self Pickup',
    deliveryOrders: 'Home Delivery',
    otherOrders: 'Host Pickup',
    product: 'Items',
    paiming: 'Best-selling items based on sales amount',
    selling: 'Sales volume',
    user: 'Customers',
    thisMonth: 'Current month',
    unComfirm2: 'Pending',
    allUser: 'All Customers',
    newUser: 'New Customers',
    oldUser: 'Existing Customers',
    turnoverChart: 'Overall Revenue',
    orderChart: 'Number of Orders',
    orderChart2: 'Number of Ticketing Orders',
    turnoverTitle: 'Revenue',
    orderTitle: 'Ticketing Revenue',
    get: 'Income',
    refund: 'Refund',
    dan: '',
    ge: 'item | items',
    ticketOrders: 'Ticketing',
    ticketIncome: 'Ticketing',
    refunding: 'Refunding',
    paidToday: 'Paid Today',
    fulfilledToday: 'Fulfilled Today',
    toDeliveryToday: 'To Delivery Today',
    salesToday: 'Sales Today',
    groupbuyAction: 'Groupbuy Action',
    pendingHostApplications: 'Pending Host Applications',
    pendingCashbackWithdrawal: 'Pending Cashback Withdrawal'
  },
  fooyoDashboard: {
    accountInfo: 'Account Information',
    storeName: 'Store Name',
    licenceId: 'Licence ID',
    entityName: 'Entity Name',
    storeWebsite: 'Store Website',
    operationReport: 'Operation Report',
    shops: 'Shops',
    allShops: 'All Shops',
    dateRange: 'Date Range',
    newUserOrders: 'New User Orders',
    newUser: 'New User',
    voucherRedemption: 'Voucher Redemption',
    voucherIssued: 'Voucher Issued',
    voucherRedeemed: 'Voucher Redeemed',
    howOrders: '{count} Orders',
    orderFulfillment: 'Order Fulfillment',
    ordersFulfilled: 'Orders Fulfilled',
    salesAndOrdersFulfilled: 'Sales and Orders Fulfilled',
    sales: 'Sales',
    orderFulfilled: 'Order Fulfilled',
    averageBasketSize: 'Average Basket Size',
    topSellingProducts: 'Top Selling Products',
    userActivity: 'User Activity',
    activeUser: 'Active User',
    inactiveUser: 'Inactive User',
    bySales: 'by Quantity',
    byAmount: 'by Sales'
  }
}
