<template>
	<div class="aside">
		<el-menu ref="appaside" @open="handleOpen" @close="handleClose" @select="handleSelect" background-color="#545c64" :unique-opened="true" text-color="#fff" active-text-color="#ffd04b" :collapse="!sidebar.opened" router>
			<template v-if="app_name === 'combine'">
				<el-menu-item index="/" v-if="app_name !== 'zoo'">
					<i class="el-icon-menu"></i>
					<span slot="title">{{$t('menu.dataKanban')}}</span>
				</el-menu-item>
				<!-- <el-submenu index="15" v-if="app_name === 'close' || app_env !== 'production'">
					<template slot="title">
						<i class="el-icon-s-home"></i>
						<span slot="title">{{$t('menu.hotels')}}</span>
					</template>
					<el-menu-item index="/hotelsManager/hotel/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.hotelList')}}</span>
					</el-menu-item>
				</el-submenu> -->
				<el-submenu index="6" v-if="app_name === 'combine'">
					<template slot="title">
						<i class="el-icon-files"></i>
						<span>{{ $t('menu.orderManagement') }}</span>
					</template>
					<el-menu-item index="/ordersManager/deliveryOrders/list">
						<i class="el-icon-truck"></i>
						<span slot="title">{{ $t('menu.orderDeliver') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/userOrders/list">
						<i class="el-icon-coordinate"></i>
						<span slot="title">{{ $t('menu.userOrder') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/breakDownOrders/list">
						<i class="el-icon-ice-cream"></i>
						<span slot="title">{{ $t('menu.breakDownOrders') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/afterSalesOrders/list">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.afterSaleOrder') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/ticketOrders/list">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.ticketOrder') }}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="6" v-if="app_name === 'zoo'">
					<template slot="title">
						<i class="el-icon-files"></i>
						<span>{{ $t('menu.orderManagement') }}</span>
					</template>
					<el-menu-item index="/ordersManager/ticketOrders/list">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.ticketOrder') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/hotelOrders/list" v-if="app_name == 'close' || app_env !== 'production'">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.hotelOrders') }}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="6" v-if="app_name !== 'combine' && app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-files"></i>
						<span>{{ $t('menu.orderManagement') }}</span>
					</template>
					<el-menu-item index="/ordersManager/dineinOrders/list">
						<i class="el-icon-food"></i>
						<span slot="title">{{ $t('menu.dineinOrders') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/userOrders/list">
						<i class="el-icon-coordinate"></i>
						<span slot="title">{{ $t('menu.userOrder') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/deliveryOrders/list">
						<i class="el-icon-truck"></i>
						<span slot="title">{{ $t('menu.orderDeliver') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/breakDownOrders/list">
						<i class="el-icon-ice-cream"></i>
						<span slot="title">{{ $t('menu.breakDownOrders') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/afterSalesOrders/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.afterSaleOrder') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/ticketOrders/list">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.ticketOrder') }}</span>
					</el-menu-item>
					<el-menu-item index="/ordersManager/hotelOrders/list" v-if="app_name == 'close' || app_env !== 'production'">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{ $t('menu.hotelOrders') }}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="17" v-if="app_name !== 'combine'">
					<template slot="title">
						<i class="el-icon-ship"></i>
						<span slot="title">{{$t('menu.zooTicketsManager')}}</span>
					</template>
					<el-menu-item index="/zooTicketsManager/tickets/list">
						<i class="el-icon-ice-cream"></i>
						<span slot="title">{{$t('menu.ticketPackage')}}</span>
					</el-menu-item>
					<el-menu-item index="/zooTicketsManager/tickets-type/list">
						<i class="el-icon-ice-cream-square"></i>
						<span slot="title">{{$t('menu.packageType')}}</span>
					</el-menu-item>
					<el-menu-item index="/zooTicketsManager/ticketPackage/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-lollipop"></i>
						<span slot="title">{{$t('menu.tPackage')}}</span>
					</el-menu-item>
					<el-menu-item index="/zooTicketsManager/multiParkTickets/list">
						<i class="el-icon-potato-strips"></i>
						<span slot="title">{{$t('menu.multiParkTickets')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="1" v-if="app_name === 'fooyo' && app_env !== 'production'">
					<template slot="title">
						<i class="el-icon-lightning"></i>
						<span>{{$t('menu.informationManagement')}}</span>
					</template>
					<el-menu-item index="/basicMsgManager/attractions/list">
						<i class="el-icon-wind-power"></i>
						<span slot="title">{{$t('menu.attractionsList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/hotels/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.hotelList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/restaurants/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.restaurantList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/campaigns/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.campaignList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/retails/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.retailList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/experiences/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.experienceList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/promotions/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.promotionList')}}</span>
					</el-menu-item>
					<el-menu-item index="/basicMsgManager/shows/list">
						<i class="el-icon-s-shop"></i>
						<span slot="title">{{$t('menu.showList')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="14" v-if="app_name === 'close' || app_env !== 'production'">
					<template slot="title">
						<i class="el-icon-monitor"></i>
						<span slot="title">{{$t('menu.rooms')}}</span>
					</template>
					<el-menu-item index="/roomsManager/room/list">
						<i class="el-icon-mobile"></i>
						<span slot="title">{{$t('menu.roomList')}}</span>
					</el-menu-item>
					<!-- <el-menu-item index="/roomsManager/policy/list">
						<i class="el-icon-open"></i>
						<span slot="title">{{$t('menu.policyList')}}</span>
					</el-menu-item> -->
				</el-submenu>
				<el-submenu index="5">
					<template slot="title">
						<i class="el-icon-shopping-cart-1"></i>
						<span>{{ $t('menu.commodityManagement') }}</span>
					</template>
					<el-menu-item index="/goodsManager/product/list">
						<i class="el-icon-shopping-cart-full"></i>
						<span slot="title">{{ $t('menu.productList') }}</span>
					</el-menu-item>
					<el-menu-item index="/goodsManager/variations/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-shopping-cart-2"></i>
						<span slot="title">{{ $t('menu.shopItemStock') }}</span>
					</el-menu-item>
					<el-menu-item index="/goodsManager/categories/list">
						<i class="el-icon-shopping-bag-1"></i>
						<span slot="title">{{ $t('menu.shopCategories') }}</span>
					</el-menu-item>
					<el-menu-item index="/goodsManager/subCategories/list">
						<i class="el-icon-shopping-bag-2"></i>
						<span slot="title">{{ $t('menu.shopSubCategories') }}</span>
					</el-menu-item>
					<el-menu-item index="/goodsManager/commonLabels/list">
						<i class="el-icon-price-tag"></i>
						<span slot="title">{{ $t('menu.shopItemLabels') }}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="4">
					<template slot="title">
						<i class="el-icon-present"></i>
						<span>{{$t('menu.storesManager')}}</span>
					</template>
					<el-menu-item index="/storesManager/shopsManager/list">
						<i class="el-icon-wallet"></i>
						<span slot="title">{{$t('menu.shopsManager')}}</span>
					</el-menu-item>
					<el-menu-item index="/storesManager/storesManager/list">
						<i class="el-icon-receiving"></i>
						<span slot="title">{{$t('form.store.offlineShop')}}</span>
					</el-menu-item>
					<el-menu-item index="/storesManager/smsRecordManager/list">
						<i class="el-icon-message"></i>
						<span slot="title">{{$t('form.store.SMSRecord')}}</span>
					</el-menu-item>
					<el-menu-item index="/storesManager/shopsLabels/list">
						<i class="el-icon-files"></i>
						<span slot="title">{{$t('form.store.categories')}}</span>
					</el-menu-item>
					<el-menu-item index="/storesManager/deliveryZones/list">
						<i class="el-icon-add-location"></i>
						<span slot="title">{{$t('form.store.deliveryZones')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-menu-item index="/suppliersManager/suppliers/list">
					<i class="el-icon-umbrella"></i>
					<span slot="title">{{$t('menu.suppliersManager')}}</span>
				</el-menu-item>
				<el-submenu index="2" v-if="app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-s-marketing"></i>
						<span>{{ $t('menu.promotions') }}</span>
						<div class="newSpan" :class="sidebar.opened ? 'hideNewSpan' : 'openNewSpan' ">new</div>
					</template>
					<el-menu-item index="/promotionManager/campaign/list">
						<i class="el-icon-shopping-bag-1"></i>
						<span slot="title">{{ $t('menu.campaigns') }}</span>
					</el-menu-item>
					<el-menu-item index="/promotionManager/flexiCombo/list">
						<i class="el-icon-discount"></i>
						<span slot="title">{{$t('menu.flexiCombo')}}</span>
					</el-menu-item>
					<el-menu-item index="/bundleManager/bundles/list">
						<i class="el-icon-sell"></i>
						<span slot="title">{{$t('menu.bundleManager')}}</span>
					</el-menu-item>
					<el-menu-item index="/promotionManager/referral/add" v-if="app_name !== 'combine'">
						<i class="el-icon-sell"></i>
						<span slot="title">{{$t('menu.referralManager')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="7" v-if="app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-s-ticket"></i>
						<span slot="title">{{$t('menu.coupons')}}</span>
					</template>
					<el-menu-item index="/couponManager/onlineCoupon/list">
						<i class="el-icon-open"></i>
						<span slot="title">{{$t('menu.onlineCoupon')}}</span>
					</el-menu-item>
					<el-menu-item index="/couponManager/offlineCoupon/list">
						<i class="el-icon-turn-off"></i>
						<span slot="title">{{$t('menu.offlineCoupon')}}</span>
					</el-menu-item>
					<el-menu-item index="/couponManager/onlineCouponRedeem/list">
						<i class="el-icon-cloudy"></i>
						<span slot="title">{{$t('menu.onlineCouponRedeem')}}</span>
					</el-menu-item>
					<el-menu-item index="/couponManager/offlineCouponRedeem/list">
						<i class="el-icon-partly-cloudy"></i>
						<span slot="title">{{$t('menu.offlineCouponRedeem')}}</span>
					</el-menu-item>
					<el-menu-item index="/couponManager/voucherPack/list">
						<i class="el-icon-cloudy-and-sunny"></i>
						<span slot="title">{{$t('menu.voucherPack')}}</span>
					</el-menu-item>
					<el-menu-item index="/couponManager/onlineVoucherCode/list">
						<i class="el-icon-full-screen"></i>
						<span slot="title">{{$t('menu.onlineVoucherCode')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="7" v-if="app_name === 'zoo'">
					<template slot="title">
						<i class="el-icon-s-ticket"></i>
						<span slot="title">{{$t('menu.coupons')}}</span>
					</template>
					<el-menu-item index="/couponManager/voucherPack/list">
						<i class="el-icon-cloudy-and-sunny"></i>
						<span slot="title">{{$t('menu.voucherPack')}}</span>
					</el-menu-item>
					<el-menu-item index="/couponManager/onlineVoucherCode/list">
						<i class="el-icon-full-screen"></i>
						<span slot="title">{{$t('menu.onlineVoucherCode')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="3" v-if="app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-burger"></i>
						<span>{{$t('menu.rewardsStore')}}</span>
					</template>
					<el-menu-item index="/rewardsStore/rewardsProduct/list">
						<i class="el-icon-shopping-bag-1"></i>
						<span slot="title">{{$t('menu.rewardsProducts')}}</span>
					</el-menu-item>
					<el-menu-item index="/rewardsStore/productCategories/list">
						<i class="el-icon-shopping-bag-1"></i>
						<span slot="title">{{$t('menu.productCategories')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="8" v-if="app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-user"></i>
						<span>{{ $t('menu.buddyManagement') }}</span>
					</template>
					<el-menu-item index="/buddyManager/buddy/list">
						<i class="el-icon-user"></i>
						<span slot="title">{{ $t('menu.buddyList') }}</span>
					</el-menu-item>
					<el-menu-item index="/buddyManager/addressType/list">
						<i class="el-icon-map-location"></i>
						<span slot="title">{{ $t('menu.buddyAdreessType') }}</span>
					</el-menu-item>
					<el-menu-item index="/buddyManager/commission/list">
						<i class="el-icon-wallet"></i>
						<span slot="title">{{ $t('menu.buddyCommission') }}</span>
					</el-menu-item>
					<el-menu-item index="/buddyManager/openCloseApplication/list">
						<i class="el-icon-shopping-bag-1"></i>
						<span slot="title">{{ $t('menu.buddyOCApplication') }}</span>
					</el-menu-item>
					<el-menu-item index="/buddyManager/apply/list">
						<i class="el-icon-user"></i>
						<span slot="title">{{ $t('menu.buddyApplication') }}</span>
					</el-menu-item>
					<el-menu-item index="/buddyManager/withdrawalApplication/list">
						<i class="el-icon-money"></i>
						<span slot="title">{{ $t('menu.buddyWithdrawApplication') }}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="9">
					<template slot="title">
						<i class="el-icon-football"></i>
						<span>{{$t('menu.decoration')}}</span>
					</template>
					<el-menu-item index="/decorationManager/banner/list">
						<i class="el-icon-guide"></i>
						<span slot="title">{{$t('menu.banner')}}</span>
					</el-menu-item>
					<el-menu-item index="/decorationManager/popups/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-coffee-cup"></i>
						<span slot="title">{{$t('menu.popUps')}}</span>
					</el-menu-item>
					<el-menu-item index="/decorationManager/navigation/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-place"></i>
						<span slot="title">{{$t('menu.navigation')}}</span>
					</el-menu-item>
					<el-menu-item index="/decorationManager/page/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-attract"></i>
						<span slot="title">{{$t('menu.customizedPage')}}</span>
					</el-menu-item>
					<el-menu-item index="/decorationManager/hotSearch/list">
						<i class="el-icon-search"></i>
						<span slot="title">{{$t('menu.hotSearch')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="10">
					<template slot="title">
						<i class="el-icon-finished"></i>
						<span>{{$t('menu.members')}}</span>
					</template>
					<el-menu-item index="/memberManager/member/list">
						<i class="el-icon-c-scale-to-original"></i>
						<span slot="title">{{$t('menu.memberList')}}</span>
					</el-menu-item>
					<!-- <el-menu-item index="/memberManager/address/list">
						<i class="el-icon-scissors"></i>
						<span slot="title">{{$t('menu.memberAddress')}}</span>
					</el-menu-item> -->
					<el-menu-item index="/memberManager/pointsRecord/list" v-if="app_name !== 'zoo'">
						<i class="el-icon-attract"></i>
						<span slot="title">{{$t('menu.pointsRecord')}}</span>
					</el-menu-item>
					<el-menu-item v-if="app_name !== 'combine' && app_name !== 'zoo'" index="/memberManager/tier/list">
						<i class="el-icon-mobile"></i>
						<span slot="title">{{$t('menu.memberTier')}}</span>
					</el-menu-item>
					<el-menu-item v-if="app_name === 'combine'" index="/memberManager/userGroup/list">
						<i class="el-icon-mobile"></i>
						<span slot="title">{{$t('menu.userGroup')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="11" v-if="app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-key"></i>
						<span>{{$t('menu.notifications')}}</span>
					</template>
					<el-menu-item index="/notificationManager/notification/list">
						<i class="el-icon-service"></i>
						<span slot="title">{{$t('menu.notificationList')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="12" v-if="app_name !== 'zoo'">
					<template slot="title">
						<i class="el-icon-sugar"></i>
						<span>{{$t('menu.luckyDraw')}}</span>
					</template>
					<el-menu-item index="/prizesManager/prizes/list">
						<i class="el-icon-dessert"></i>
						<span slot="title">{{$t('menu.prizeManagement')}}</span>
					</el-menu-item>
					<el-menu-item index="/prizesManager/participation/list">
						<i class="el-icon-ice-cream"></i>
						<span slot="title">{{$t('menu.participationRecords')}}</span>
					</el-menu-item>
					<el-menu-item index="/prizesManager/winning/list">
						<i class="el-icon-hot-water"></i>
						<span slot="title">{{$t('menu.winningRecords')}}</span>
					</el-menu-item>
					<el-menu-item index="/prizesManager/luckyDraw/list">
						<i class="el-icon-water-cup"></i>
						<span slot="title">{{$t('menu.luckyDrawChance')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-submenu index="13">
					<template slot="title">
						<i class="el-icon-data-analysis"></i>
						<span>{{$t('menu.setting')}}</span>
					</template>
					<el-menu-item index="/settingManager/admin/list" v-if="app_name !== 'combine'">
						<i class="el-icon-user-solid"></i>
						<span slot="title">{{$t('menu.adminUser')}}</span>
					</el-menu-item>
					<el-menu-item index="/settingManager/client/list">
						<i class="el-icon-film"></i>
						<span slot="title">{{$t('menu.clients')}}</span>
					</el-menu-item>
					<el-menu-item index="/settingManager/countries/list">
						<i class="el-icon-place"></i>
						<span slot="title">{{$t('menu.countries')}}</span>
					</el-menu-item>
				</el-submenu>
				<el-menu-item index="/downloadCenter/downloadCenter">
					<i class="el-icon-menu"></i>
					<span slot="title">{{ $t('menu.downloadCenter') }}</span>
				</el-menu-item>
			</template>
			<template v-if="app_name !== 'combine'">
				<template v-for="(item, index) in permissions">
					<template v-if="item.permission">
						<el-submenu :index="'parent' + index" :key="index" v-if="item.children && item.children.length > 0">
							<template slot="title">
								<i :class="item.icon"></i>
								<span slot="title">{{item.title}}</span>
							</template>
							<div v-for="(child, childIndex) in item.children" :key="'child' + childIndex">
								<template v-if="child.permission">
									<el-menu-item :index="child.route" >
										<i :class="child.icon"></i>
										<span slot="title">{{child.title}}</span>
									</el-menu-item>
								</template>
							</div>
						</el-submenu>
						<el-menu-item :key="'other' + index" :index="item.route" v-else>
							<i :class="item.icon"></i>
							<span slot="title">{{item.title}}</span>
						</el-menu-item>
					</template>
				</template>
			</template>
		</el-menu>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppAside',
  data () {
    return {
      collapse: true,
      app_env: process.env.NODE_ENV,
      app_name: process.env.VUE_APP_NAME
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      permissions: (state) => state.permissions
    }),
    classObj () {
      return {
        hideSidebar: this.sidebar.opened
        // openSidebar: this.sidebar.opened
      }
    }
  },
  watch: {
    $route () {
      this.getheader(true)
    }
  },
  created () {
    // console.log(this.permissions)
    // console.log(this.sidebar.opened)
    this.getheader(true)
  },
  methods: {
    // 获取首页
    getheader (flag) {
      const data = this.$route.matched.filter((item) => item.name)
      this.$nextTick(() => {
        if (data[0].name === 'home') {
          this.$refs.appaside.activeIndex = '/'
          this.$refs.appaside.openedMenus = ['/']
        } else {
          if (flag) {
            const routePath = data[0].path.split('/')
            if (data[0].path === '/promotionManager/referral/add') {
              this.$refs.appaside.activeIndex = routePath.join('/')
            } else {
              const routeArray = [
                routePath[0],
                routePath[1],
                routePath[2],
                'list'
              ]
              this.$refs.appaside.activeIndex = routeArray.join('/')
              // this.$refs.appaside.openedMenus.push(data[0].meta.index.toString())
              // this.getMenuName(data[0].meta.index.toString())
            }
          }
        }
      })
    },
    handleOpen (key, keyPath) {
      // console.log('1', key, keyPath)
      // console.log(this.$refs.appaside)
    },
    //
    handleClose (key, keyPath) {
      // console.log('2', key, keyPath)
    },
    //
    handleSelect (key, keyPath) {
      // this.getMenuName(keyPath[0])
    },
    getMenuName (index) {
      this.$store.commit(
        'app/TOGGLE_NAME',
        this.map.get(index) === undefined ? '' : this.map.get(index)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
	height: 100%;
	// 菜单收缩栏样式(关闭时)
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
	.el-menu:not(.el-menu--collapse) {
		width: 200px;
	}
	.el-menu {
		height: 100%;
	}
	.newSpan {
		transform: translateY(-50%);
		width: 36px;
		height: 16px;
		background: #d74747;
		color: #fff;
		font-size: 12px;
		border-radius: 8px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hideNewSpan {
		top: 50%;
		right: 40px;
	}
	.openNewSpan {
		top: 30%;
		right: 0;
	}
}
</style>
<style lang="scss">
.aside {
	.el-menu {
		.el-tooltip {
			padding-left: 17px !important;
		}
		.el-menu-item i {
			color: #fff;
		}
		.el-submenu {
			.el-submenu__title {
				display: flex;
				align-items: center;
			}
			.el-submenu__title span {
				white-space: normal;
				word-break: break-word;
				line-height: 20px;
				flex: 1;
				padding-right: 20px;
			}
			.el-submenu__title i {
				color: #fff;
			}
			.el-menu-item {
				display: flex;
				align-items: center;
				background-color: #3c434a !important;
				padding-right: 20px !important;
				&:hover {
					background-color: #2c3238 !important;
				}
			}
			.el-menu-item span {
				white-space: normal;
				word-break: break-word;
				line-height: 20px;
				flex: 1;
			}
			.el-menu-item {
				padding-left: 25px !important;
			}
		}
		.is-active {
			> div {
				color: rgb(255, 208, 75) !important;
				> i {
					color: rgb(255, 208, 75) !important;
				}
			}
			> i {
				color: rgb(255, 208, 75) !important;
			}
		}
	}
}
</style>
