import i18n from '@/i18n/index'

const suppliersManager = [
  {
    path: '/suppliersManager',
    component: () => import('@/views/suppliersManager/index.vue'),
    meta: { name: i18n.t('menu.suppliersManager'), allowBreadcrumbRedirect: false },
    children: [
      {
        path: '/suppliersManager/suppliers',
        component: () => import('@/views/suppliersManager/suppliers/index.vue'),
        meta: { name: i18n.t('menu.suppliers'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'suppliersManagerList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'suppliersManagerList',
            component: () => import('@/views/suppliersManager/suppliers/list/index.vue'),
            meta: { name: i18n.t('text.list') }
          },
          {
            // 添加
            path: 'add',
            name: 'suppliersAdd',
            component: () => import('@/views/suppliersManager/suppliers/add/index.vue'),
            meta: { name: i18n.t('text.add') }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'suppliersEdit',
            component: () => import('@/views/suppliersManager/suppliers/edit/index.vue'),
            meta: { name: i18n.t('text.edit') }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'suppliersDetail',
            component: () => import('@/views/suppliersManager/suppliers/detail/index.vue'),
            meta: { name: i18n.t('text.detail') }
          }
        ]
      }
    ]
  }
]

export default suppliersManager
