import i18n from '@/i18n/index'

const buddyManager = [
  {
    path: '/buddyManager',
    component: () => import('@/views/buddyManager/index.vue'),
    meta: { name: i18n.t('menu.buddyManagement'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 团长列表
        path: '/buddyManager/buddy',
        component: () => import('@/views/buddyManager/buddy/index.vue'),
        meta: { name: i18n.t('menu.buddyList'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'buddyManagerBuddyList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'buddyManagerBuddyList',
            component: () => import('@/views/buddyManager/buddy/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 列表
            path: 'list',
            name: 'buddyManagerAddressList',
            component: () => import('@/views/buddyManager/address/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'buddyManagerAddressEdit',
            component: () => import('@/views/buddyManager/address/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'buddyManagerAddressDetail',
            component: () => import('@/views/buddyManager/address/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'buddyManagerBuddyEdit',
            component: () => import('@/views/buddyManager/buddy/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 编辑
            path: 'detail/:id',
            name: 'buddyManagerBuddyDetail',
            component: () => import('@/views/buddyManager/buddy/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 地址类型
        path: '/buddyManager/addressType',
        component: () => import('@/views/buddyManager/addressType/index.vue'),
        meta: { name: i18n.t('menu.buddyAdreessType'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'buddyManagerAddressTypeList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'buddyManagerAddressTypeList',
            component: () => import('@/views/buddyManager/addressType/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'add',
            name: 'buddyManagerAddressTypeAdd',
            component: () => import('@/views/buddyManager/addressType/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 编辑
            path: 'edit/:id',
            name: 'buddyManagerAddressTypeEdit',
            component: () => import('@/views/buddyManager/addressType/edit/index.vue'),
            meta: { name: i18n.t('text.edit'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'buddyManagerAddressTypeDetail',
            component: () => import('@/views/buddyManager/addressType/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 团长提成
        path: '/buddyManager/commission',
        component: () => import('@/views/buddyManager/commission/index.vue'),
        meta: { name: i18n.t('menu.buddyCommission'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'buddyManagerCommissionList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'buddyManagerCommissionList',
            component: () => import('@/views/buddyManager/commission/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 编辑
            path: 'add',
            name: 'buddyManagerCommissionAdd',
            component: () => import('@/views/buddyManager/commission/add/index.vue'),
            meta: { name: i18n.t('text.add'), keepAlive: false }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'buddyManagerCommissionDetail',
            component: () => import('@/views/buddyManager/commission/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 团长开关店申请
        path: '/buddyManager/openCloseApplication',
        component: () => import('@/views/buddyManager/openCloseApplication/index.vue'),
        meta: { name: i18n.t('menu.buddyOCApplication'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'buddyManagerOpenCloseApplicationList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'buddyManagerOpenCloseApplicationList',
            component: () => import('@/views/buddyManager/openCloseApplication/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'buddyManagerOpenCloseApplicationDetail',
            component: () => import('@/views/buddyManager/openCloseApplication/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 团长申请
        path: '/buddyManager/apply',
        component: () => import('@/views/buddyManager/apply/index.vue'),
        meta: { name: i18n.t('menu.buddyApplication'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'buddyManagerApplyList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'buddyManagerApplyList',
            component: () => import('@/views/buddyManager/apply/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'buddyManagerApplyDetail',
            component: () => import('@/views/buddyManager/apply/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 团长提现申请
        path: '/buddyManager/withdrawalApplication',
        component: () => import('@/views/buddyManager/withdrawalApplication/index.vue'),
        meta: { name: i18n.t('menu.buddyWithdrawApplication'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'buddyManagerWithdrawalApplicationList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'buddyManagerWithdrawalApplicationList',
            component: () => import('@/views/buddyManager/withdrawalApplication/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'buddyManagerWithdrawalApplicationDetail',
            component: () => import('@/views/buddyManager/withdrawalApplication/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default buddyManager
