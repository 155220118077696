import i18n from '@/i18n/index'

const orderManager = [
  {
    path: '/ordersManager',
    component: () => import('@/views/ordersManager/index.vue'),
    meta: { name: i18n.t('menu.orderManagement'), allowBreadcrumbRedirect: false },
    children: [
      {
        // 售后订单
        path: '/ordersManager/afterSalesOrders',
        component: () => import('@/views/ordersManager/afterSalesOrders/index.vue'),
        meta: { name: i18n.t('menu.afterSaleOrder'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerAfterSalesOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerAfterSalesOrdersList',
            component: () => import('@/views/ordersManager/afterSalesOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerAfterSalesOrdersDetail',
            component: () => import('@/views/ordersManager/afterSalesOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'afterSalesOrdersDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 拆分订单
        path: '/ordersManager/breakDownOrders',
        component: () => import('@/views/ordersManager/breakDownOrders/index.vue'),
        meta: { name: i18n.t('menu.breakDownOrders'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerBreakDownOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerBreakDownOrdersList',
            component: () => import('@/views/ordersManager/breakDownOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerBreakDownOrdersDetail',
            component: () => import('@/views/ordersManager/breakDownOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'breakDownDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 配送订单
        path: '/ordersManager/deliveryOrders',
        component: () => import('@/views/ordersManager/deliveryOrders/index.vue'),
        meta: { name: i18n.t('menu.orderDeliver'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerDeliveryOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerDeliveryOrdersList',
            component: () => import('@/views/ordersManager/deliveryOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerDeliveryOrdersDetail',
            component: () => import('@/views/ordersManager/deliveryOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'deliveryOrdersDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 用户订单
        path: '/ordersManager/userOrders',
        component: () => import('@/views/ordersManager/userOrders/index.vue'),
        meta: { name: i18n.t('menu.userOrder'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerUserOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerUserOrdersList',
            component: () => import('@/views/ordersManager/userOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerUserOrdersDetail',
            component: () => import('@/views/ordersManager/userOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          },
          {
            path: 'downloadCenter',
            name: 'userOrdersDownloadCenter',
            component: () => import(/* webpackChunkName: 'downloadCenter' */ '@/views/downloadCenter/downloadCenter.vue'),
            meta: { name: i18n.t('menu.downloadCenter'), keepAlive: false }
          }
        ]
      },
      {
        // 门票订单
        path: '/ordersManager/ticketOrders',
        component: () => import('@/views/ordersManager/ticketOrders/index.vue'),
        meta: { name: i18n.t('menu.ticketOrder'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerticketOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerticketOrdersList',
            component: () => import('@/views/ordersManager/ticketOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerTicketOrdersDetail',
            component: () => import('@/views/ordersManager/ticketOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 门票订单
        path: '/ordersManager/hotelOrders',
        component: () => import('@/views/ordersManager/hotelOrders/index.vue'),
        meta: { name: i18n.t('menu.hotelOrders'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'hotelOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'hotelOrdersList',
            component: () => import('@/views/ordersManager/hotelOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'hotelOrdersDetail',
            component: () => import('@/views/ordersManager/hotelOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 堂食订单
        path: '/ordersManager/dineinOrders',
        component: () => import('@/views/ordersManager/dineinOrders/index.vue'),
        meta: { name: i18n.t('menu.dineinOrders'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerDineinOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerDineinOrdersList',
            component: () => import('@/views/ordersManager/dineinOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerDineinOrdersDetail',
            component: () => import('@/views/ordersManager/dineinOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      },
      {
        // 酒店订单
        path: '/ordersManager/restOrders',
        component: () => import('@/views/ordersManager/restOrders/index.vue'),
        meta: { name: i18n.t('menu.restOrder'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManageRestOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManageRestOrdersList',
            component: () => import('@/views/ordersManager/restOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerRestOrdersDetail',
            component: () => import('@/views/ordersManager/restOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }, 
      {
         // 优惠券订单
         path: '/ordersManager/couponOrders',
         component: () => import('@/views/ordersManager/couponOrders/index.vue'),
         meta: { name: i18n.t('menu.couponOrders'), allowBreadcrumbRedirect: true },
         redirect: {
           name: 'ordersManagerCouponOrdersList'
         },
         children: [
           {
             // 列表
             path: 'list',
             name: 'ordersManagerCouponOrdersList',
             component: () => import('@/views/ordersManager/couponOrders/list/index.vue'),
             meta: { name: i18n.t('text.list'), keepAlive: true }
           },
           {
            path: 'detail/:id',
            name: 'ordersManagerCouponOrdersDetail',
            component: () => import('@/views/ordersManager/couponOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
           }
         ]
      },
      {
        // 优惠券礼包订单
        path: '/ordersManager/couponBundleOrders',
        component: () => import('@/views/ordersManager/couponBundleOrders/index.vue'),
        meta: { name: i18n.t('menu.couponBundleOrders'), allowBreadcrumbRedirect: true },
        redirect: {
          name: 'ordersManagerCouponBundleOrdersList'
        },
        children: [
          {
            // 列表
            path: 'list',
            name: 'ordersManagerCouponBundleOrdersList',
            component: () => import('@/views/ordersManager/couponBundleOrders/list/index.vue'),
            meta: { name: i18n.t('text.list'), keepAlive: true }
          },
          {
            // 详情
            path: 'detail/:id',
            name: 'ordersManagerCouponBundleOrdersDetail',
            component: () => import('@/views/ordersManager/couponBundleOrders/detail/index.vue'),
            meta: { name: i18n.t('text.detail'), keepAlive: false }
          }
        ]
      }
    ]
  }
]

export default orderManager
