const env = process.env.NODE_ENV
const name = process.env.VUE_APP_NAME
function getDifAddress (name) {
  let apiUrl = null
  switch (name) {
    case 'defalut':
      apiUrl = env === 'production' ? 'https://singapore.fooyotravel.com/' : 'https://singapore-staging.fooyotravel.com/'
      break
    case 'combine':
      apiUrl = env === 'production' ? 'https://cms.combine.sg/' : 'https://combine-uat.fooyotravel.com/'
      break
    case 'fooyo':
      apiUrl = env === 'production' ? 'https://singapore.fooyotravel.com/' : 'https://singapore-staging.fooyotravel.com/'
      break
    case 'zoo':
      apiUrl = env === 'production' ? 'https://singapore-zoo.fooyotravel.com/' : 'https://singapore-staging.fooyotravel.com/'
      break
  }
  return apiUrl
}
const apiUrl = getDifAddress(name)
export default apiUrl
